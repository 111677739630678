import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Skeleton,
  Heading,
  Text,
  Button,
  Flex,
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  useToast,
} from '@chakra-ui/react'
import { Section } from '../../components/base/Section'
import {
  useDestroyVapidKeysMutation,
  useGenerateVapidKeysMutation,
  useSetVapidSignatureMailToMutation,
} from '../../graphql'
import { useVersion } from 'chakra-admin'
import MDXCodeBlock from '../../components/CodeBlock/MDXCodeBlock'

const CreateVapidKeys: FC<any> = ({ record }) => {
  const version = useVersion()
  const [generate, { loading, data }] = useGenerateVapidKeysMutation({
    onCompleted: () => {
      version()
    },
  })

  const handleGenerate = useCallback(() => {
    if (!record?.id) {
      return
    }

    return generate({
      variables: {
        appId: record.id,
      },
    })
  }, [generate, record.id])

  return (
    <>
      <Text mt={4}>No VAPID Keys found. Generate them in order to use Web Push Notifications.</Text>

      <Button onClick={handleGenerate} mt={4} isLoading={loading} isDisabled={loading}>
        Create VAPID Keys
      </Button>
    </>
  )
}

const CurrentVapidKeys: FC<any> = ({ record }) => {
  const nextVersion = useVersion()
  const toast = useToast()
  const [destroyVapidKeys, { loading: destroyingVapidKeys }] = useDestroyVapidKeysMutation({
    onCompleted: () => {
      nextVersion()
    },
  })

  const [setVapidSignatureMailTo, { loading: savingMailTo }] = useSetVapidSignatureMailToMutation({
    onCompleted(data) {
      if (data?.setVapidSignatureMailto) {
        nextVersion()
        setMailTo(data.setVapidSignatureMailto)
        toast({
          title: 'Mailto salvato',
          description: `La mail ${data.setVapidSignatureMailto} è stata impostata come mail amministrativa per le notifiche push`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      }
    },
    onError(error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const [mailTo, setMailTo] = useState<string | undefined>(record?.vapidSignatureMailto)

  const handleMailToChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setMailTo(event.target.value)
  }, [])

  const isEmailValid = useMemo(() => {
    return !!mailTo && new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(mailTo)
  }, [mailTo])

  const isError = useMemo(() => {
    return !mailTo || !isEmailValid
  }, [isEmailValid, mailTo])

  const handleDestroy = useCallback(async () => {
    if (!record?.id) {
      return
    }

    return destroyVapidKeys({
      variables: {
        appId: record.id,
      },
    })
  }, [destroyVapidKeys, record?.id])

  const handleSaveMailTo = useCallback(
    async (event: any) => {
      event.preventDefault()
      console.log('handleSubmit', origin, record?.id)
      if (!record?.id || isError) {
        return
      }

      await setVapidSignatureMailTo({
        variables: {
          appId: record?.id,
          mailto: mailTo || '',
        },
      })
    },
    [isError, mailTo, record?.id, setVapidSignatureMailTo]
  )

  useEffect(() => {
    setMailTo(record?.vapidSignatureMailto)
  }, [record?.vapidSignatureMailto])

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-start" mb={4}>
        <Box>
          <Heading as="h2" size="md" mb={2}>
            Web Push
          </Heading>
          <Text>
            Keys creation: <b>{record?.vapidKeysCreatedAt}</b>
          </Text>
        </Box>

        <Button
          colorScheme="red"
          onClick={handleDestroy}
          isLoading={destroyingVapidKeys}
          isDisabled={destroyingVapidKeys}
        >
          Destroy VAPID Keys
        </Button>
      </Flex>

      <Box w="100%">
        <Heading size="md" mb={2}>
          Public VAPID Key:
        </Heading>
        <Text>
          The public VAPID key is used to communicate with push services. It is safe to share this
          key with your users in your web applications.
        </Text>
        <MDXCodeBlock children={record.vapidPublicKey} />
      </Box>

      <Box as="form" w="100%" mt={4} onSubmit={handleSaveMailTo}>
        <Heading size="md" mb={2}>
          Mail To:
        </Heading>

        <FormControl mt={4} isInvalid={isError}>
          <FormLabel>Change Vapid Signature mailto:</FormLabel>
          <Input
            maxW="450px"
            value={mailTo}
            onChange={handleMailToChange}
            placeholder="admin@example.com"
          />
          {isError && (
            <FormErrorMessage>
              {mailTo ? 'Invalid email address' : 'This field is required'}
            </FormErrorMessage>
          )}
        </FormControl>

        <Button
          size="sm"
          mt={4}
          type="submit"
          isDisabled={savingMailTo || isError || !mailTo}
          isLoading={savingMailTo}
        >
          Save
        </Button>
      </Box>
    </>
  )
}

export const WebPushChannel = (props: any) => {
  const hasVapidKeys = useMemo(() => props?.record?.hasVapidKeys, [props?.record])

  return (
    <Section>
      {hasVapidKeys ? <CurrentVapidKeys {...props} /> : <CreateVapidKeys {...props} />}
    </Section>
  )
}

import { ApolloClient, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'

export const client = new ApolloClient({
  cache: new InMemoryCache({}),
  link: createUploadLink({
    uri: process.env.REACT_APP_API_URI,
    credentials: 'include',
    headers: {
      'X-Requested-With': 'fetch',
    },
  }),
})

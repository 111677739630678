import React, { FC, useMemo } from 'react'
import { ca } from 'chakra-admin'
import { Tag, TagLeftIcon, TagLabel, Box, Flex } from '@chakra-ui/react'
import { MdEmail, MdNotifications, MdSms } from 'react-icons/md'

const ChannelColors = {
  EMAIL: 'blue',
  SMS: 'green',
  WEB_PUSH: 'purple',
}

const ChannelIcons = {
  EMAIL: MdEmail,
  SMS: MdSms,
  WEB_PUSH: MdNotifications,
}

type UserChannelFieldProps = {
  id?: string
  channel?: 'EMAIL' | 'SMS' | 'WEB_PUSH'
  details?: Record<string, any>
  active?: boolean
}

export const UserChannelTags: FC<UserChannelFieldProps> = ({ id, channel, details }) => {
  return (
    <Tag
      mt={1}
      mx={0.5}
      size="sm"
      colorScheme={channel && ChannelColors[channel]}
      borderRadius="full"
    >
      {channel && <TagLeftIcon as={ChannelIcons[channel]} />}
      <TagLabel>{(channel === 'EMAIL' || channel === 'SMS') && (details || {}).value}</TagLabel>
    </Tag>
  )
}

type UserChannelsProps = {
  channels?: UserChannelFieldProps[]
}

export const UserChannels: FC<UserChannelsProps> = ({ channels }) => {
  return (
    <Flex minW="350px">
      {channels
        ?.filter((channel) => channel.active)
        ?.map((channel, index) => {
          return <UserChannelTags key={channel?.id || index} {...channel} />
        })}
    </Flex>
  )
}

export const UserChannelsField = ca.field<UserChannelsProps>(UserChannels, {
  target: 'channels',
})

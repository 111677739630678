import React, { FC } from 'react'
// import { MUTATION_UPDATE_NOTIFICATION, QUERY_GET_NOTIFICATION } from '../../queries'
// import { NotificationForm } from './NotificationForm'
// import { Edit } from '../../components/details/Edit'
// import { BaseForm } from '../../components/forms/BaseForm'

export const NotificationEdit: FC = (props) => {
  return <div>TODO</div>
  // return (
  //   <Edit query={QUERY_GET_NOTIFICATION} mutation={MUTATION_UPDATE_NOTIFICATION} {...props}>
  //     <BaseForm {...props}>
  //       <NotificationForm isEdit />
  //     </BaseForm>
  //   </Edit>
  // )
}

import React, { FC } from 'react'
import { MUTATION_UPDATE_NOTIFICATION_TYPE, QUERY_GET_NOTIFICATION_TYPE } from '../../queries'
import { NotificationTypeForm } from './NotificationTypeForm'
import { Edit } from '../../components/details/Edit'
import { BaseForm } from '../../components/forms/BaseForm'
import { PageLayout } from '../../components/details/PageLayout'
import { PageTitle } from '../../components/details/PageTitle'

export const NotificationTypeEdit: FC = (props) => {
  return (
    <Edit
      query={QUERY_GET_NOTIFICATION_TYPE}
      mutation={MUTATION_UPDATE_NOTIFICATION_TYPE}
      layout={<PageLayout />}
      title={(<PageTitle />) as any}
      {...props}
    >
      <BaseForm>
        <NotificationTypeForm {...props} isEdit />
      </BaseForm>
    </Edit>
  )
}

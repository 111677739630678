/* eslint-disable react/jsx-key */
import React, { FC } from 'react'
import {
  DataTable,
  DataTableValue as Field,
  GenericMoreMenuButton,
  Input,
  List,
  ListToolbar,
  Filters,
} from 'chakra-admin'
import { MUTATION_DELETE_NOTIFICATION, QUERY_GET_NOTIFICATIONS } from '../../queries'
import { gql_Query, gql_Notification } from '../../graphql'
import { useBreakpointValue, Text } from '@chakra-ui/react'
// import { Filters } from '../../components/list/Filters'
import { CreateButton } from '../../components/buttons/CreateButton'
import { PageLayout } from '../../components/details/PageLayout'
import { PageTitle } from '../../components/details/PageTitle'
import { NotificationContentField } from './NotificationContentField'
import { NotificationChannelTagsField } from './NotificationContentChannelsField'

const NotificationFilters: FC = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <Filters {...props}>
      <Input
        source="email"
        label="Email"
        placeholder="Cerca email..."
        alwaysOn={isMobile ? false : true}
        border="none"
        bgColor="transparent"
        _hover={{ bgColor: 'transparent' }}
        borderRadius="xl"
        boxShadow="0px 1px 4px rgba(0, 0, 0, 0.05), 0px 6px 24px rgba(0, 0, 0, 0.04), inset 0px 1px 1px rgba(0, 0, 0, 0.04)"
      />

      <Input source="firstName" label="Nome" />
      <Input source="lastName" label="Cognome" />
    </Filters>
  )
}

export const NotificationList: FC = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <List<gql_Query, gql_Notification>
      paginationMode="cursor"
      layout={<PageLayout />}
      title={(<PageTitle />) as any}
      query={QUERY_GET_NOTIFICATIONS}
      deleteItemMutation={MUTATION_DELETE_NOTIFICATION}
      filtersComponent={<NotificationFilters />}
      toolbarComponent={
        <ListToolbar>
          <CreateButton openAsModal label={isMobile ? 'Crea' : true} />
        </ListToolbar>
      }
      {...props}
    >
      <DataTable
        rowClick={{ asModal: true }}
        moreMenuComponent={<GenericMoreMenuButton openEditAsModal />}
      >
        {/* <Field<gql_Notification> source="id" /> */}

        <NotificationChannelTagsField
          sources={{
            rawContent: 'rawContent',
            notificationType: 'notificationType',
          }}
        />
        <NotificationContentField
          sources={{
            rawContent: 'rawContent',
            notificationType: 'notificationType',
          }}
        />
        <Field source="createdAt" />
        <Field source="addedToQueueAt" />
      </DataTable>
    </List>
  )
}

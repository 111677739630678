/* eslint-disable react/jsx-key */
import React, { FC } from 'react'
import {
  DataTable,
  DataTableValue as Field,
  GenericMoreMenuButton,
  Input,
  List,
  ListToolbar,
  Filters,
  StatusField,
  AutocompleteInput,
} from 'chakra-admin'
import {
  MUTATION_DELETE_NOTIFICATION_TYPE,
  QUERY_GET_APPS,
  QUERY_GET_NOTIFICATION_TYPES,
} from '../../queries'
import { gql_Query, gql_NotificationType } from '../../graphql'
import { Avatar, useBreakpointValue } from '@chakra-ui/react'
// import { Filters } from '../../components/list/Filters'
import { CreateButton } from '../../components/buttons/CreateButton'
import { PageLayout } from '../../components/details/PageLayout'
import { PageTitle } from '../../components/details/PageTitle'

const NotificationTypeFilters: FC = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <Filters {...props}>
      <AutocompleteInput
        paginationMode="cursor"
        emptyLabel="Tutte le app"
        showEmptyState
        query={QUERY_GET_APPS as any}
        source="appId"
        label=""
        placeholder="Cerca per app"
        labelStyleProps={{ fontSize: 'md', fontWeight: '600', mt: '2', pb: '2' }}
        showFormControl={false}
        alwaysOn={!isMobile}
        // maxW="300px"
        minW="300px"
        inputValueToFilters={(name: string) => ({ name })}
        dataItemToAutocompleteItem={(data) => ({
          ...data,
          label: `${data.name}`,
          value: data.id,
        })}
        inputStyleProps={{ borderRadius: 'lg' }}
      />

      <Input mb={2} ml={4} source="name" alwaysOn label="Nome" />
    </Filters>
  )
}

export const NotificationTypeList: FC = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <List<gql_Query, gql_NotificationType>
      paginationMode="cursor"
      layout={<PageLayout />}
      title={(<PageTitle />) as any}
      query={QUERY_GET_NOTIFICATION_TYPES}
      deleteItemMutation={MUTATION_DELETE_NOTIFICATION_TYPE}
      filtersComponent={<NotificationTypeFilters />}
      toolbarComponent={
        <ListToolbar>
          <CreateButton label={isMobile ? 'Crea' : true} />
        </ListToolbar>
      }
      {...props}
    >
      <DataTable moreMenuComponent={<GenericMoreMenuButton openEditAsModal />}>
        {/* <Field<gql_NotificationType> source="id" /> */}
        <Field source="name" />
        <Field source="slug" />
        <Field source="app.name" />

        <StatusField
          source="notificationCenterEnabled"
          statuses={{
            false: {
              label: 'Nascosto dal centro notifiche',
              statusColor: 'orange.300',
            },
            true: {
              label: 'Visibile nel centro notifiche',
              statusColor: 'green.300',
            },
          }}
        />
        <StatusField
          source="emailEnabled"
          statuses={{
            true: {
              label: 'Email abilitata',
              statusColor: 'green.300',
            },
            false: {
              label: 'Email disabilitata',
              statusColor: 'gray.300',
            },
          }}
        />

        <StatusField
          source="smsEnabled"
          statuses={{
            true: {
              label: 'SMS abilitato',
              statusColor: 'green.300',
            },
            false: {
              label: 'SMS disabilitato',
              statusColor: 'gray.300',
            },
          }}
        />

        <StatusField
          source="webPushEnabled"
          statuses={{
            true: {
              label: 'WebPush abilitate',
              statusColor: 'green.300',
            },
            false: {
              label: 'WebPush disabilitate',
              statusColor: 'gray.300',
            },
          }}
        />

        <StatusField
          source="active"
          statuses={{
            true: {
              label: 'Attivo',
              statusColor: 'green.300',
            },
            false: {
              label: 'Disabilitato',
              statusColor: 'gray.300',
            },
          }}
        />
      </DataTable>
    </List>
  )
}

import React, { FC } from 'react'
import { MUTATION_UPDATE_ADMIN, QUERY_GET_ADMIN } from '../../queries'
import { AdminForm } from './AdminForm'
import { Edit } from '../../components/details/Edit'
import { BaseForm } from '../../components/forms/BaseForm'

export const AdminEdit: FC = (props) => {
  return (
    <Edit query={QUERY_GET_ADMIN} mutation={MUTATION_UPDATE_ADMIN} {...props}>
      <BaseForm {...props}>
        <AdminForm isEdit />
      </BaseForm>
    </Edit>
  )
}

/* eslint-disable react/jsx-key */
import React, { FC } from 'react'
import {
  DataTable,
  DataTableValue as Field,
  GenericMoreMenuButton,
  Input,
  List,
  ListToolbar,
  Filters,
} from 'chakra-admin'
import { MUTATION_DELETE_ADMIN, QUERY_GET_ADMINS } from '../../queries'
import { gql_Query, gql_Admin } from '../../graphql'
import { Avatar, useBreakpointValue } from '@chakra-ui/react'
// import { Filters } from '../../components/list/Filters'
import { CreateButton } from '../../components/buttons/CreateButton'
import { PageLayout } from '../../components/details/PageLayout'
import { PageTitle } from '../../components/details/PageTitle'

const AdminFilters: FC = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <Filters {...props}>
      <Input
        source="email"
        label="Email"
        placeholder="Cerca email..."
        alwaysOn={isMobile ? false : true}
        border="none"
        bgColor="transparent"
        _hover={{ bgColor: 'transparent' }}
        borderRadius="xl"
        boxShadow="0px 1px 4px rgba(0, 0, 0, 0.05), 0px 6px 24px rgba(0, 0, 0, 0.04), inset 0px 1px 1px rgba(0, 0, 0, 0.04)"
      />

      <Input source="firstName" label="Nome" />
      <Input source="lastName" label="Cognome" />
    </Filters>
  )
}

export const AdminList: FC = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <List<gql_Query, gql_Admin>
      paginationMode="cursor"
      layout={<PageLayout />}
      title={(<PageTitle />) as any}
      query={QUERY_GET_ADMINS}
      deleteItemMutation={MUTATION_DELETE_ADMIN}
      filtersComponent={<AdminFilters />}
      toolbarComponent={
        <ListToolbar>
          <CreateButton openAsModal label={isMobile ? 'Crea' : true} />
        </ListToolbar>
      }
      {...props}
    >
      <DataTable
        rowClick={{ asModal: true }}
        moreMenuComponent={<GenericMoreMenuButton openEditAsModal />}
      >
        <Field<gql_Admin> source="id" />
        <Field<gql_Admin>
          source="picture"
          render={({ record }: any) => {
            return (
              <Avatar
                src={record?.picture?.urlSmall}
                name={`${record?.firstName} ${record?.lastName}`}
              />
            )
          }}
        />
        <Field<gql_Admin> source="email" />
        <Field<gql_Admin> source="firstName" />
        <Field<gql_Admin> source="lastName" />
      </DataTable>
    </List>
  )
}

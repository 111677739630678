import React, { FC } from 'react'
import { Box, Button, Input, useClipboard } from '@chakra-ui/react'

type Props = {
  code?: string
}

export const CopyButton: FC<Props> = ({ code }) => {
  const { hasCopied, onCopy } = useClipboard(code || '')

  return (
    <Box mb={2} pos="absolute" right={3} top={3}>
      <Input value={code} isReadOnly placeholder="Welcome" display="none" />
      <Button onClick={onCopy} ml={2} size="sm">
        {hasCopied ? 'Copied' : 'Copy'}
      </Button>
    </Box>
  )
}

import React, { FC } from 'react'
import { Avatar, Box, Flex, FlexProps, Text, Tooltip, useBreakpointValue } from '@chakra-ui/react'
import { useAuthUser, useDeleteWithConfirm } from 'chakra-admin'
import { LogoutMenu } from './LogoutMenu'

type Props = {
  isCollapsed: boolean
} & FlexProps

export const AccountBox: FC<Props> = ({ isCollapsed, ...props }) => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  })
  const { initialized, user } = useAuthUser()
  const { isOpen, onClose, onOpen } = useDeleteWithConfirm({})

  return (
    <Flex
      p={isMobile || !isCollapsed ? 3.5 : 0}
      mb={5}
      boxShadow={
        isMobile || !isCollapsed
          ? '0px 1px 4px rgba(0, 0, 0, 0.05), 0px 6px 24px rgba(0, 0, 0, 0.04), inset 0px 1px 1px rgba(0, 0, 0, 0.04)'
          : ''
      }
      borderColor="gray.200"
      borderRadius="xl"
      justifyContent="space-between"
      alignItems="center"
      bg={isMobile || !isCollapsed ? 'white' : 'transparent'}
      {...props}
    >
      <Flex width="fit-content" alignItems="center" transform="translateX(5px)">
        <Tooltip
          isDisabled={isMobile || !isCollapsed ? true : false}
          label="Clicca per uscire"
          portalProps={{ appendToParentPortal: true }}
        >
          <Box border="2px solid" borderColor="gray.200" w="38x" h="38px" borderRadius="full">
            <Avatar
              src={user?.avatarUrl}
              maxW="34px"
              maxH="34px"
              bg="gray.200"
              cursor={isMobile || !isCollapsed ? 'unset' : 'pointer'}
              onClick={isMobile || !isCollapsed ? undefined : onOpen}
            />
          </Box>
        </Tooltip>
        {isMobile || !isCollapsed ? (
          <Box ml={4}>
            <Text
              color="gray.700"
              fontWeight="500"
              textTransform="capitalize"
              lineHeight="17.64px"
              letterSpacing="0.2px"
              fontSize="md"
              noOfLines={1}
            >
              {user?.fullName}
            </Text>
            <Text textStyle="description" textTransform="capitalize" fontSize="xs" noOfLines={1}>
              {user?.email}
            </Text>
          </Box>
        ) : null}
      </Flex>
      <LogoutMenu
        user={user}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        isCollapsed={isCollapsed}
      />
    </Flex>
  )
}

import React, { FC, useMemo } from 'react'
import { Box } from '@chakra-ui/react'

import Highlight, { defaultProps, Language } from 'prism-react-renderer'
import githubTheme from 'prism-react-renderer/themes/palenight'
import { CopyButton } from './CopyButton'
import { CodeContainer } from './CodeContainer'

// const ReactLiveBlock = dynamic(() => import('./ReactLiveBlock').then((mod) => mod.ReactLiveBlock))

type MDXCodeBlock = {
  className?: string
  children?: string
  // live?: boolean
  noInline?: boolean
  language?: Language
  noCodePreview?: boolean
  maxLineWidth?: number
}

const MDXCodeBlock: FC<MDXCodeBlock> = ({
  /*live,*/
  className = '',
  children = '',
  language: langProp,
  noInline,
  noCodePreview,
  maxLineWidth,
}) => {
  // const shadow = useColorModeValue('surface', 'surfaceDark')
  const language = useMemo(
    () => langProp || (className.replace(/language-/, '') as Language),
    [className, langProp]
  )

  const trimmedCode = useMemo(() => children.trim(), [children])

  // if (live) {
  //   return (
  //     <ReactLiveBlock
  //       noInline={noInline}
  //       noCodePreview={noCodePreview}
  //       code={trimmedCode}
  //       theme={githubTheme}
  //       language={language}
  //     />
  //   )
  // }

  return (
    <CodeContainer>
      <Box overflow="scroll">
        <Highlight {...defaultProps} theme={githubTheme} code={trimmedCode} language={language}>
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre
              className={className}
              style={{
                ...style,
                backgroundColor: 'transparent',
              }}
            >
              {tokens.map((line, i) => (
                <div key={`line-${i}`} {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <span
                      key={key}
                      {...getTokenProps({ token, key })}
                      // style={{ color: 'white' }}
                    />
                  ))}
                </div>
              ))}
            </pre>
          )}
        </Highlight>
      </Box>
      <CopyButton code={children} />
    </CodeContainer>
  )
}

export default MDXCodeBlock

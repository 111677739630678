import React, { FC, useCallback, useMemo } from 'react'
import {
  Box,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
} from '@chakra-ui/react'
import { ca, EditButton, Show, ShowToolbar, StatusField } from 'chakra-admin'
import { gql_App } from '../../graphql'
import { MUTATION_UPDATE_APP, QUERY_GET_APP } from '../../queries'
import { ApiKeyManager } from './ApiKeyManager'
import { PageTitle } from '../../components/details/PageTitle'
import { useSearchParams } from 'react-router-dom'
import { WidgetsConfiguration } from './WidgetsConfiguration'
import { AppChannels } from './AppChannels'

const TitleLabel = (record: any) => {
  return (
    <Flex alignItems="center">
      {record?.name}
      <AppStatusField record={record} />
    </Flex>
  )
}

const AppStatusField = (props: any) => {
  return (
    <Tooltip
      label={
        props.record?.active && props.record?.hasApiKey
          ? "L'app può inviare notifiche"
          : props.record?.active && !props.record?.hasApiKey
          ? "L'app può inviare notifiche ma non ha ancora un API Key"
          : "L'app è disabilitata"
      }
    >
      <Box fontWeight="bold">
        <StatusField<gql_App>
          {...props}
          ml={3}
          label="resources.App.fields.active"
          source={(record) =>
            record?.active && record?.hasApiKey
              ? 'active'
              : record?.active && !record?.hasApiKey
              ? 'noApiKey'
              : 'disabled'
          }
          statuses={{
            active: {
              label: ' ',
              statusColor: 'green.300',
            },
            noApiKey: {
              label: ' ',
              statusColor: 'yellow.300',
            },
            disabled: {
              label: ' ',
              statusColor: 'gray.300',
            },
          }}
        />
      </Box>
    </Tooltip>
  )
}

const AppTabs = (props: any) => {
  const { record, loading } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const isTabActive = useMemo(
    () => record?.active && record?.hasApiKey,
    [record?.active, record?.hasApiKey]
  )
  const tabIndex = isTabActive ? (searchParams.has('t') ? parseInt(searchParams.get('t')!) : 0) : 2

  const handleTabsChange = useCallback(
    (index: number) => {
      setSearchParams({ t: `${index}` })
    },
    [setSearchParams]
  )

  return (
    <Tabs
      isLazy
      size="sm"
      variant="line"
      index={tabIndex}
      onChange={handleTabsChange}
      colorScheme="primary"
    >
      <TabList mb={4} mx={6}>
        {/* <Tab isDisabled={!isTabActive}>Notification Types</Tab> */}
        <Tab isDisabled={!isTabActive}>Notification Center</Tab>
        <Tab isDisabled={!isTabActive}>Channels</Tab>
        <Tab>API Key</Tab>
      </TabList>
      <TabPanels>
        {/* <TabPanel>Ciao</TabPanel> */}
        <TabPanel>
          <Heading mx={6} fontSize="3xl">
            Widgets Configuration
          </Heading>
          <WidgetsConfiguration {...props} />
        </TabPanel>
        <TabPanel>
          <Heading mx={6} fontSize="3xl">
            App Channels
          </Heading>
          <AppChannels {...props} />
        </TabPanel>
        <TabPanel>
          <Heading mx={6} fontSize="3xl">
            API Key
          </Heading>
          <ApiKeyManager {...props} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export const AppShow: FC = (props) => {
  return (
    <Show<gql_App>
      query={QUERY_GET_APP}
      mutation={MUTATION_UPDATE_APP}
      title={(<PageTitle customLabel={TitleLabel as any} />) as any}
      toolbarComponent={
        <ShowToolbar>
          <EditButton openAsModal />
        </ShowToolbar>
      }
      {...props}
    >
      <AppTabs />
    </Show>
  )
}

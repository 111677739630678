/* eslint-disable react/jsx-key */
import React, { FC } from 'react'
import {
  DataTable,
  DataTableValue as Field,
  GenericMoreMenuButton,
  Input,
  List,
  ListToolbar,
  Filters,
  StatusField,
} from 'chakra-admin'
import { MUTATION_DELETE_APP, QUERY_GET_APPS } from '../../queries'
import { gql_Query, gql_App } from '../../graphql'
import { Avatar, useBreakpointValue } from '@chakra-ui/react'
// import { Filters } from '../../components/list/Filters'
import { CreateButton } from '../../components/buttons/CreateButton'
import { PageLayout } from '../../components/details/PageLayout'
import { PageTitle } from '../../components/details/PageTitle'

const AppFilters: FC = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <Filters {...props}>
      <Input
        source="name"
        label="Nome"
        placeholder="Cerca per nome..."
        alwaysOn={isMobile ? false : true}
        border="none"
        bgColor="transparent"
        _hover={{ bgColor: 'transparent' }}
        borderRadius="xl"
        boxShadow="0px 1px 4px rgba(0, 0, 0, 0.05), 0px 6px 24px rgba(0, 0, 0, 0.04), inset 0px 1px 1px rgba(0, 0, 0, 0.04)"
      />
    </Filters>
  )
}

export const AppList: FC = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <List<gql_Query, gql_App>
      paginationMode="cursor"
      layout={<PageLayout />}
      title={(<PageTitle />) as any}
      query={QUERY_GET_APPS}
      deleteItemMutation={MUTATION_DELETE_APP}
      filtersComponent={<AppFilters />}
      toolbarComponent={
        <ListToolbar>
          <CreateButton openAsModal label={isMobile ? 'Crea' : true} />
        </ListToolbar>
      }
      {...props}
    >
      <DataTable rowClick="show" moreMenuComponent={<GenericMoreMenuButton openEditAsModal />}>
        <Field<gql_App> source="name" />
        <StatusField<gql_App>
          label="resources.App.fields.active"
          source={(record: any) =>
            (record?.active && record?.hasApiKey
              ? 'active'
              : record?.active && !record?.hasApiKey
              ? 'noApiKey'
              : 'disabled') as any
          }
          statuses={{
            active: {
              label: 'Attiva',
              statusColor: 'green.300',
            },
            noApiKey: {
              label: 'Nessuna chiave API',
              statusColor: 'yellow.300',
            },
            disabled: {
              label: 'Disabilitata',
              statusColor: 'gray.300',
            },
          }}
        />
        <Field<gql_App> source="updatedAt" />
      </DataTable>
    </List>
  )
}

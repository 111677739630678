import React, { FC, useMemo } from 'react'
import { HStack, Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react'
import { AiOutlineBell } from 'react-icons/ai'
import { MdEmail, MdSms, MdNotifications } from 'react-icons/md'
import { Content } from './NotificationContentField'
import { ca } from 'chakra-admin'

const ChannelColors = {
  EMAIL: 'blue',
  SMS: 'green',
  WEB_PUSH: 'purple',
  NOTIFICATION_CENTER: 'green',
}

const ChannelIcons = {
  NOTIFICATION_CENTER: AiOutlineBell,
  EMAIL: MdEmail,
  SMS: MdSms,
  WEB_PUSH: MdNotifications,
}

const ChannelLabels = {
  NOTIFICATION_CENTER: 'in app',
  EMAIL: 'email',
  SMS: 'sms',
  WEB_PUSH: 'web',
}

type NotificationChannelProps = {
  channel: 'EMAIL' | 'SMS' | 'WEB_PUSH' | 'NOTIFICATION_CENTER'
}

export const NotificationChannelTag: FC<NotificationChannelProps> = ({ channel }) => {
  return (
    <Tag mx={0.5} size="sm" colorScheme={(ChannelColors as any)[channel]} borderRadius="full">
      <TagLeftIcon as={ChannelIcons[channel]} />
      <TagLabel>{ChannelLabels[channel]}</TagLabel>
    </Tag>
  )
}

type NotificationChannelTagsProps = {
  rawContent?: any
  notificationType?: any
}

export const NotificationChannelTags: FC<NotificationChannelTagsProps> = ({
  notificationType,
  rawContent,
}) => {
  const content = useMemo(() => notificationType || rawContent, [notificationType, rawContent])

  if (!content) {
    return null
  }

  return (
    <HStack spacing={1.5} alignItems="center">
      {content.notificationCenterEnabled && (
        <NotificationChannelTag channel="NOTIFICATION_CENTER" />
      )}
      {content.webPushEnabled && <NotificationChannelTag channel="WEB_PUSH" />}
      {content.emailEnabled && <NotificationChannelTag channel="EMAIL" />}
      {content.smsEnabled && <NotificationChannelTag channel="SMS" />}
    </HStack>
  )
}

export const NotificationChannelTagsField =
  ca.f<NotificationChannelTagsProps>(NotificationChannelTags)

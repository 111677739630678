/* eslint-disable react/jsx-key */
import React, { FC } from 'react'
import {
  DataTable,
  DataTableValue as Field,
  GenericMoreMenuButton,
  Input,
  List,
  ListToolbar,
  Filters,
  AutocompleteInput,
} from 'chakra-admin'
import { MUTATION_DELETE_USER, QUERY_GET_APPS, QUERY_GET_USERS } from '../../queries'
import { gql_Query, gql_User } from '../../graphql'
import { useBreakpointValue } from '@chakra-ui/react'
// import { Filters } from '../../components/list/Filters'
import { CreateButton } from '../../components/buttons/CreateButton'
import { PageLayout } from '../../components/details/PageLayout'
import { PageTitle } from '../../components/details/PageTitle'
import { UserChannelsField } from './UserChannelField'

const UserFilters: FC = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <Filters {...props}>
      <AutocompleteInput
        paginationMode="cursor"
        emptyLabel="Tutte le app"
        showEmptyState
        query={QUERY_GET_APPS as any}
        source="appId"
        label=""
        placeholder="Cerca per app"
        labelStyleProps={{ fontSize: 'md', fontWeight: '600', mt: '2', pb: '2' }}
        showFormControl={false}
        alwaysOn={!isMobile}
        // maxW="300px"
        minW="300px"
        inputValueToFilters={(name: string) => ({ name })}
        dataItemToAutocompleteItem={(data) => ({
          ...data,
          label: `${data.name}`,
          value: data.id,
        })}
        inputStyleProps={{ borderRadius: 'lg' }}
      />

      <Input mb={2} ml={4} source="refId" alwaysOn label="ID Riferimento" />
    </Filters>
  )
}

export const UserList: FC = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <List<gql_Query, gql_User>
      paginationMode="cursor"
      layout={<PageLayout />}
      title={(<PageTitle />) as any}
      query={QUERY_GET_USERS}
      deleteItemMutation={MUTATION_DELETE_USER}
      filtersComponent={<UserFilters />}
      toolbarComponent={
        <ListToolbar>
          <CreateButton openAsModal label={isMobile ? 'Crea' : true} />
        </ListToolbar>
      }
      {...props}
    >
      <DataTable
        rowClick={{ asModal: true }}
        moreMenuComponent={<GenericMoreMenuButton openEditAsModal />}
      >
        {/* <Field<gql_User> source="id" /> */}
        <Field<gql_User> source="refId" />
        <Field source="app.name" label="App" />

        <UserChannelsField source="channels" />
        <Field<gql_User> source="updatedAt" />
      </DataTable>
    </List>
  )
}

import React, { FC, useMemo } from 'react'
import { Heading, Box, Skeleton, useBreakpointValue, Center, Icon } from '@chakra-ui/react'
import { PageTitleProps, useTranslate } from 'chakra-admin'
import { Breadcrumbs } from '../layout/Breadcrumbs'

export const PageTitle: FC<PageTitleProps & { customLabel?: (record: any) => string }> = ({
  label,
  renderingInModal,
  loading,
  resource,
  data,
  error,
  record,
  icon,
  customLabel,
  ...rest
}) => {
  const t = useTranslate()
  // if (renderingInModal) {
  //   return (
  //     <DrawerHeader
  //       fontSize="2xl"
  //       display="flex"
  //       alignItems="center"
  //       justifyContent="space-between"
  //       // TODO: fix boxshadow color
  //       boxShadow="0px 3px 12px rgba(37, 31, 30, 0.05)"
  //       {...rest}
  //     >
  //       <Flex alignItems="center">
  //         {boxComponent ||
  //           (icon ? (
  //             <Center bgColor="gray.100" w="44px" h="44px" borderRadius="md" mr={4}>
  //               <Icon color="gray.500" as={icon} fontSize="2xl" />
  //             </Center>
  //           ) : null)}

  //         {label}
  //       </Flex>

  //       <ModalCloseButton pos="relative" top="none" insetEnd="none" />
  //     </DrawerHeader>
  //   )
  // }
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const title = useMemo(() => {
    if (customLabel) {
      return customLabel(record)
    }

    return label
  }, [customLabel, label, record])

  return (
    <Box
      display="flex"
      flexDir="column"
      alignItems="flex-start"
      justifyContent="center"
      // mr={2}
      mx={6}
      {...rest}
    >
      {!isMobile && <Breadcrumbs />}
      <Skeleton isLoaded={!loading}>
        <Heading mt={2} as="h1" display="flex" fontSize={{ base: '2xl', lg: '4xl' }}>
          {icon ? (
            <Center bgColor="gray.100" w="44px" h="44px" borderRadius="md" mr={4}>
              <Icon color="gray.500" as={icon} fontSize="2xl" />
            </Center>
          ) : null}
          {title || t('ca.message.loading')}
        </Heading>
      </Skeleton>
    </Box>
  )
}

import * as React from 'react'

import { Admin, defaultI18n, en, getDefaultI18nOptions, Resource } from 'chakra-admin'
import { theme } from './theme'
import { ChakraProvider } from '@chakra-ui/react'
import { MashupAuthProvider } from './MashupAuthProvider'
import { client } from './apolloClient'

import { it } from './it'
import { RecoilRoot } from 'recoil'

import './App.css'
import { Global } from '@emotion/react'
import { RouteLayout } from './components/layout/stable/RouteLayout'

import '@fontsource/dm-sans'
import { Login } from './components/login/Login'

import AdminResource from './resources/Admin'
import AppResource from './resources/App'

import { MashupStrategy } from './strategies/MashupStrategy'
import { ModalRouteLayout } from './components/modals/ModalRouteLayout'
import User from './resources/User'
import NotificationType from './resources/NotificationType'
import Notification from './resources/Notification'

export const App = () => {
  const isWindows = React.useMemo(() => {
    return navigator.userAgent.indexOf('Windows') !== -1
  }, [])

  return (
    <RecoilRoot>
      {isWindows && (
        <Global
          styles={{
            'div::-webkit-scrollbar': {
              width: '10px',
              height: '10px',
            },
            'div::-webkit-scrollbar-track': {
              background: 'transparent',
            },

            'div::-webkit-scrollbar-thumb': {
              backgroundColor: 'var(--chakra-colors-blackAlpha-200)',
              borderRadius: '20px',
            },

            'div::-webkit-scrollbar-thumb:hover': {
              backgroundColor: 'var(--chakra-colors-blackAlpha-400)',
            },
          }}
        />
      )}
      <ChakraProvider theme={theme} portalZIndex={10}>
        {/* <ThemeEditorProvider> */}
        <Admin
          client={client}
          loginComponent={<Login />}
          strategy={MashupStrategy}
          authProvider={MashupAuthProvider}
          layoutComponent={<RouteLayout />}
          // modalComponent={<ModalRouteLayout />}
          i18nProviderProps={{
            i18n: defaultI18n,
            options: {
              ...getDefaultI18nOptions({ en, it }),
              fallbackLng: 'it',
            },
          }}
        >
          <Resource {...(Notification as any)} />
          <Resource {...(NotificationType as any)} />
          <Resource {...(AppResource as any)} />
          <Resource {...(User as any)} />
          <Resource {...(AdminResource as any)} />
          {/* <Route index element={<div>Eh si!</div>} /> */}
        </Admin>
        {/* <HyperThemeEditor pos="fixed" bottom={4} right={4} />
        </ThemeEditorProvider> */}
      </ChakraProvider>
    </RecoilRoot>
  )
}

import React, { FC } from 'react'
import { MUTATION_CREATE_NOTIFICATION_TYPE } from '../../queries'
import { BaseForm } from '../../components/forms/BaseForm'
import { NotificationTypeForm } from './NotificationTypeForm'
import { Create } from '../../components/details/Create'
import { PageLayout } from '../../components/details/PageLayout'
import { PageTitle } from '../../components/details/PageTitle'

export const NotificationTypeCreate: FC = (props) => {
  return (
    <Create
      mutation={MUTATION_CREATE_NOTIFICATION_TYPE}
      layout={<PageLayout />}
      title={(<PageTitle />) as any}
      {...props}
    >
      <BaseForm>
        <NotificationTypeForm {...props} />
      </BaseForm>
    </Create>
  )
}

import React, { FC, useCallback, useMemo } from 'react'
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react'
import { useVersion } from 'chakra-admin'
import MDXCodeBlock from '../../components/CodeBlock/MDXCodeBlock'
import { useGenerateApiKeyMutation, useDestroyApiKeyMutation } from '../../graphql'
import { DisabledAppAlert } from './DisabledAppAlert'
import { Section } from '../../components/base/Section'

const AppIdSection: FC<{ appId: string }> = ({ appId }) => {
  return (
    <>
      <Heading size="md" mb={2}>
        App ID:
      </Heading>
      <Text>
        The App ID is a unique identifier for your app. It is used to identify your app when you
        make API requests.
      </Text>
      <MDXCodeBlock children={appId} />
    </>
  )
}

const ApiKeySection: FC<{ apiKey: string; showCopyNowAlert?: boolean }> = ({
  apiKey,
  showCopyNowAlert = false,
}) => {
  return (
    <>
      <Heading size="md" mb={2}>
        API Key:
      </Heading>
      <Text>
        The API Key is used to authenticate your app when you make API requests from the backend.
      </Text>
      {showCopyNowAlert && (
        <>
          <Text fontWeight="bold">This key must be kept private.</Text>
          <Text fontWeight="bold">
            If you refresh or leave this page, you will not be able to see this key again.
          </Text>
        </>
      )}
      <MDXCodeBlock children={apiKey} />
    </>
  )
}

export const CreateApiKey: FC<any> = ({ record }) => {
  const [generateApiKey, { data, loading, error }] = useGenerateApiKeyMutation()

  const handleGenerateApiKey = useCallback(async () => {
    if (!record?.id) {
      return
    }

    return generateApiKey({
      variables: {
        appId: record.id,
      },
    })
  }, [generateApiKey, record?.id])

  return (
    <Section display="flex" flexDir="column" alignItems="center" justifyContent="center">
      {data?.generateApiKey?.apiKey ? (
        <Box w="100%">
          <AppIdSection appId={`${record?.id}`} />
          <ApiKeySection apiKey={`${data?.generateApiKey?.apiKey}`} showCopyNowAlert />
        </Box>
      ) : record?.active ? (
        <>
          <Text mt={4}>No API key found. Create one to start sending notifications.</Text>

          <Button onClick={handleGenerateApiKey} mt={4} isLoading={loading} isDisabled={loading}>
            Create API key
          </Button>
        </>
      ) : (
        <Text fontSize="xl" my={4}>
          This app is disabled. Activate it to create API keys.
        </Text>
      )}
    </Section>
  )
}

export const CurrentApiKey: FC<any> = ({ record }) => {
  const nextVersion = useVersion()
  const [destroyApiKey, { loading, data, error }] = useDestroyApiKeyMutation({
    onCompleted: () => {
      nextVersion()
    },
  })

  const handleDestroyApiKey = useCallback(async () => {
    if (!record?.id) {
      return
    }

    return destroyApiKey({
      variables: {
        appId: record.id,
      },
    })
  }, [destroyApiKey, record?.id])

  return (
    <Box>
      {!record?.active && <DisabledAppAlert />}

      <Section>
        <Flex justifyContent="space-between">
          <Text>
            Keys creation: <b>{record?.apiKeyCreatedAt}</b>
          </Text>

          <Button
            colorScheme="red"
            onClick={handleDestroyApiKey}
            isLoading={loading}
            isDisabled={loading}
          >
            Destroy Key
          </Button>
        </Flex>

        <Box w="100%">
          <AppIdSection appId={record?.id} />
          <ApiKeySection apiKey="// Private key is not visible after creation" />
        </Box>
      </Section>
    </Box>
  )
}

export const ApiKeyManager: FC<any> = (props) => {
  const hasApiKey = useMemo(() => {
    return props.record?.hasApiKey
  }, [props.record?.hasApiKey])

  return <Box>{!hasApiKey ? <CreateApiKey {...props} /> : <CurrentApiKey {...props} />}</Box>
}

import { Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react'

export const DisabledAppAlert = () => {
  return (
    <Alert status="warning" p={4} m={4} borderRadius="xl">
      <AlertIcon />
      <AlertTitle>App is disabled.</AlertTitle>
      <AlertDescription>Activate it to send notifications.</AlertDescription>
    </Alert>
  )
}

import React, { FC, useEffect } from 'react'
import {
  Text,
  Box,
  Collapse,
  Flex,
  Heading,
  IconButton,
  Stack,
  Textarea,
  useDisclosure,
  Input,
  FormHelperText,
  Select,
  FormErrorMessage,
} from '@chakra-ui/react'
import { AutocompleteInput, ca, Query, useTranslate } from 'chakra-admin'
import { Section } from '../../components/base/Section'
import { gql_App } from '../../graphql'
import { QUERY_GET_APP, QUERY_GET_APPS } from '../../queries'
import { Controller, useWatch } from 'react-hook-form'
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons'
import Editor from '@monaco-editor/react'

export const AppInfo: FC<any> = (props) => {
  console.log('AppInfo', props)
  return (
    <Section>
      <Heading mb={2} as="h2" size="md">
        App
      </Heading>
      {props.isEdit ? (
        <Query
          query={QUERY_GET_APP}
          type="show"
          resource="App"
          variables={{ id: props.record?.appId }}
        >
          <ca.Text source="name" />
        </Query>
      ) : (
        <ca.FormControl {...props} source="appId" mt={4}>
          <AutocompleteInput
            {...props}
            paginationMode="cursor"
            inputStyleProps={{
              variant: 'outline',
              borderRadius: 'lg',
            }}
            labelStyleProps={{
              display: 'none',
            }}
            toggleButtonStyleProps={{
              display: 'none',
            }}
            query={QUERY_GET_APPS}
            source="appId"
            label="App"
            placeholder="Seleziona Applicazione"
            disableCreateItem
            showFormControl={false}
            inputValueToFilters={(name: string) => ({ name })}
            dataItemToAutocompleteItem={(data: gql_App) => ({
              ...data,
              label: data.name,
              value: data.id,
            })}
          />
        </ca.FormControl>
      )}
    </Section>
  )
}

export const HeadInfo: FC<any> = (props) => {
  const t = useTranslate()

  return (
    <Section>
      <Heading mb={2} as="h2" size="md">
        Informazioni di base
      </Heading>
      <ca.FormControl {...props} source="name" mt={4}>
        <ca.FormLabel />
        <ca.Input
          {...props}
          source="name"
          required={t('ca.message.required_field')}
          maxW="350px"
          variant="outline"
          placeholder={t(`resources.NotificationType.placeholders.name`)}
          borderRadius="lg"
        />
      </ca.FormControl>
      <ca.FormControl {...props} source="slug" mt={4}>
        <ca.FormLabel />
        <ca.Input
          {...props}
          source="slug"
          required={t('ca.message.required_field')}
          maxW="350px"
          variant="outline"
          placeholder={t(`resources.NotificationType.placeholders.slug`)}
          borderRadius="lg"
        />
        <FormHelperText>
          Inserisci uno slug per identificare il tipo di notifica direttamente dalle api.
        </FormHelperText>
      </ca.FormControl>

      <ca.FormControl {...props} source="description" mt={4}>
        <ca.FormLabel />
        <Textarea
          {...props.register('description', { required: t('ca.message.required_field') })}
        />
      </ca.FormControl>

      <ca.FormControl {...props} source="active" mt={4} display="flex" alignItems="center">
        <ca.FormLabel mb={0} htmlFor="notification-type-active" />
        <ca.Switch {...props} id="notification-type-active" source="active" />
      </ca.FormControl>
    </Section>
  )
}

export const NotificationCenterTemplateForm: FC<any> = (props) => {
  const form = useWatch({ control: props.control })
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()

  useEffect(() => {
    if (form?.notificationCenterEnabled && !isOpen) {
      onOpen()
    } else if (!form?.notificationCenterEnabled && isOpen) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.notificationCenterEnabled])

  return (
    <Section>
      <Flex w="100%" justifyContent="space-between" alignItems="center">
        <Heading mb={2} as="h2" size="md">
          Template Notification Center
        </Heading>

        <Box>
          <ca.Switch {...props} source="notificationCenterEnabled" />
          <IconButton
            size="sm"
            variant="ghost"
            borderRadius="full"
            aria-label=""
            icon={isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            onClick={onToggle}
            isDisabled={!form?.notificationCenterEnabled}
            ml={2}
          />
        </Box>
      </Flex>

      <Collapse in={isOpen} unmountOnExit>
        <Stack spacing={4}>
          <ca.FormControl {...props} source="notificationCenterTitle" mt={4}>
            <ca.FormLabel>Titolo/Corpo notifica</ca.FormLabel>
            <Input
              type="text"
              {...props.register('notificationCenterTitle')}
              placeholder="Inserisci titolo notifica"
            />
          </ca.FormControl>
          <ca.FormControl {...props} source="notificationCenterBody" mt={4}>
            <ca.FormLabel>Corpo notifica</ca.FormLabel>
            <Textarea
              {...props.register('notificationCenterBody')}
              placeholder="Inserisci corpo Notifica"
            />
          </ca.FormControl>

          <ca.FormControl {...props} source="notificationCenterIcon" mt={4}>
            <ca.FormLabel>Icona notifica</ca.FormLabel>
            <Input
              type="text"
              {...props.register('notificationCenterIcon')}
              placeholder="Inserisci URL icona"
            />
          </ca.FormControl>

          <ca.FormControl {...props} source="notificationCenterClickUrl" mt={4}>
            <ca.FormLabel>URL di destinazione al click della notifica (opzionale)</ca.FormLabel>
            <Input
              type="text"
              {...props.register('notificationCenterClickUrl')}
              placeholder="Inserisci URL click"
            />
          </ca.FormControl>
        </Stack>
      </Collapse>
    </Section>
  )
}

export const EmailTemplateForm: FC<any> = (props) => {
  const form = useWatch({ control: props.control })
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()

  useEffect(() => {
    if (form?.emailEnabled && !isOpen) {
      onOpen()
    } else if (!form?.emailEnabled && isOpen) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.emailEnabled])

  return (
    <Section>
      <Flex w="100%" justifyContent="space-between" alignItems="center">
        <Heading mb={2} as="h2" size="md">
          Template Email
        </Heading>

        <Box>
          <ca.Switch {...props} source="emailEnabled" />
          <IconButton
            size="sm"
            variant="ghost"
            borderRadius="full"
            aria-label=""
            icon={isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            onClick={onToggle}
            isDisabled={!form?.emailEnabled}
            ml={2}
          />
        </Box>
      </Flex>

      <Collapse in={isOpen} unmountOnExit>
        <Stack spacing={4}>
          <ca.FormControl {...props} source="emailTemplateSubject" mt={4}>
            <ca.FormLabel />
            <ca.Input
              {...props}
              source="emailTemplateSubject"
              variant="outline"
              placeholder="Inserisci oggetto email"
              borderRadius="lg"
            />
          </ca.FormControl>

          <ca.FormControl {...props} source="emailTemplateText" mt={4}>
            <ca.FormLabel />
            <Textarea
              {...props.register('emailTemplateText')}
              placeholder="Inserisci corpo email"
            />
          </ca.FormControl>

          <Heading mb={2} as="h3" size="xs">
            Template HTML:
          </Heading>

          <Controller
            name="tempEmailTemplateHtml"
            control={props.control}
            render={({ field, fieldState }) => {
              return (
                <Editor
                  height="90vh"
                  width="100%"
                  defaultLanguage="html"
                  value={field.value || ''}
                  onChange={field.onChange}
                  options={{
                    minimap: { enabled: true },
                  }}
                />
              )
            }}
          />
        </Stack>
      </Collapse>
    </Section>
  )
}

export const SmsTemplateForm: FC<any> = (props) => {
  const form = useWatch({ control: props.control })
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()

  useEffect(() => {
    if (form?.smsEnabled && !isOpen) {
      onOpen()
    } else if (!form?.smsEnabled && isOpen) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.smsEnabled])

  return (
    <Section>
      <Flex w="100%" justifyContent="space-between" alignItems="center">
        <Heading mb={2} as="h2" size="md">
          Template SMS
        </Heading>

        <Box>
          <ca.Switch {...props} source="smsEnabled" />
          <IconButton
            size="sm"
            variant="ghost"
            borderRadius="full"
            aria-label=""
            icon={isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            onClick={onToggle}
            isDisabled={!form?.smsEnabled}
            ml={2}
          />
        </Box>
      </Flex>

      <Collapse in={isOpen} unmountOnExit>
        <Stack spacing={4}>
          <ca.FormControl {...props} source="smsTemplate" mt={4}>
            <ca.FormLabel />
            <Textarea {...props.register('smsTemplate')} placeholder="Inserisci corpo SMS" />
          </ca.FormControl>
        </Stack>
      </Collapse>
    </Section>
  )
}

export const WebPushTemplateForm: FC<any> = (props) => {
  const form = useWatch({ control: props.control })
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()

  useEffect(() => {
    if (form?.webPushEnabled && !isOpen) {
      onOpen()
    } else if (!form?.webPushEnabled && isOpen) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.webPushEnabled])

  return (
    <Section>
      <Flex w="100%" justifyContent="space-between" alignItems="center">
        <Heading mb={2} as="h2" size="md">
          Template Web Push
        </Heading>

        <Box>
          <ca.Switch {...props} source="webPushEnabled" />
          <IconButton
            size="sm"
            variant="ghost"
            borderRadius="full"
            aria-label=""
            icon={isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            onClick={onToggle}
            isDisabled={!form?.webPushEnabled}
            ml={2}
          />
        </Box>
      </Flex>

      <Collapse in={isOpen} unmountOnExit>
        <Stack spacing={4}>
          <ca.FormControl {...props} source="webPushTemplate.title" mt={4}>
            <ca.FormLabel>Titolo</ca.FormLabel>
            <Input
              {...props.register('webPushTemplate.title')}
              placeholder="Inserisci titolo notifica"
            />
          </ca.FormControl>
          <ca.FormControl {...props} source="webPushTemplate.body" mt={4}>
            <ca.FormLabel>Corpo Notifica</ca.FormLabel>
            <Textarea
              {...props.register('webPushTemplate.body')}
              placeholder="Inserisci corpo notifica"
            />
          </ca.FormControl>

          <ca.FormControl {...props} source="webPushTemplate.icon" mt={4}>
            <ca.FormLabel>Icona</ca.FormLabel>
            <Input
              {...props.register('webPushTemplate.icon')}
              placeholder="Inserisci icona notifica"
            />
          </ca.FormControl>

          <ca.FormControl {...props} source="webPushTemplate.click_url" mt={4}>
            <ca.FormLabel>Url di destinazione al click</ca.FormLabel>
            <Input
              {...props.register('webPushTemplate.click_url')}
              placeholder="Inserisci url notifica"
            />
          </ca.FormControl>

          <ca.FormControl {...props} source="webPushTemplate.badge" mt={4}>
            <ca.FormLabel>Badge</ca.FormLabel>
            <Input
              {...props.register('webPushTemplate.badge')}
              placeholder="Inserisci badge notifica"
            />
            <FormHelperText>
              Mostra questa immagine quando non c'è spazio per mostrare la notifica
            </FormHelperText>
          </ca.FormControl>

          <ca.FormControl {...props} source="webPushTemplate.image" mt={4}>
            <ca.FormLabel>Immagine di copertina della notifica</ca.FormLabel>
            <Input
              {...props.register('webPushTemplate.image')}
              placeholder="Inserisci immagine notifica"
            />
          </ca.FormControl>

          <ca.FormControl {...props} source="webPushTemplate.tag" mt={4}>
            <ca.FormLabel>Tag</ca.FormLabel>
            <FormHelperText mb={1}>
              Identifica univocamente la notifica. Se due notifiche hanno lo stesso tag, la nuova
              sovrascrive la vecchia nella notifica.
            </FormHelperText>
            <Input
              {...props.register('webPushTemplate.tag')}
              placeholder="Inserisci tag notifica"
            />
          </ca.FormControl>

          <ca.FormControl {...props} source="webPushTemplate.dir" mt={4}>
            <ca.FormLabel>Direzione testo</ca.FormLabel>
            <Select
              {...props.register('webPushTemplate.dir', { defaultValue: 'auto' })}
              placeholder="Inserisci direzione notifica"
            >
              <option value="auto">auto</option>
              <option value="ltr">ltr</option>
              <option value="rtl">rtl</option>
            </Select>
            <FormHelperText fontWeight="semibold">Campo non calcolato</FormHelperText>
          </ca.FormControl>

          <ca.FormControl {...props} source="webPushTemplate.sound" mt={4}>
            <ca.FormLabel>Sound</ca.FormLabel>
            <FormHelperText mb={1}>Suono inviato dalla notifica (es: "default")</FormHelperText>
            <Input
              {...props.register('webPushTemplate.sound')}
              placeholder="Inserisci sound notifica"
            />
          </ca.FormControl>
        </Stack>
      </Collapse>
    </Section>
  )
}

export const NotificationTypeForm: FC<any> = ({ isEdit, ...props }) => {
  return (
    <Stack spacing="4" mt={3} mb={20}>
      <AppInfo {...props} isEdit={isEdit} />
      <HeadInfo {...props} />
      <NotificationCenterTemplateForm {...props} />
      <EmailTemplateForm {...props} />
      <SmsTemplateForm {...props} />
      <WebPushTemplateForm {...props} />
    </Stack>
  )
}

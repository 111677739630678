import React, { FC } from 'react'
import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react'

export type CodeContainerProps = BoxProps

export const CodeContainer: FC<CodeContainerProps> = (props) => {
  return (
    <Box
      p={5}
      bgColor={useColorModeValue('gray.800', 'gray.900')}
      borderRadius="lg"
      pos="relative"
      my={4}
      {...props}
    />
  )
}

import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { Button, chakra, Grid, Heading, Skeleton, Text } from '@chakra-ui/react'
import { Section } from '../../components/base/Section'
import { gql_Channel, useGetAppChannelQuery, useSetAppChannelMutation } from '../../graphql'
import { useForm } from 'react-hook-form'
import { ca } from 'chakra-admin'
import { WebPushChannel } from './WebPushChannel'

const EmailChannel = (props: any) => {
  const { loading, data, error } = useGetAppChannelQuery({
    variables: {
      appId: props.id,
      channel: gql_Channel.Email,
    },
  })

  const methods = useForm({})

  const inputProps = useMemo(() => {
    return {
      setValue: methods.setValue,
      register: methods.register,
      unregister: methods.unregister,
      control: methods.control,
      resource: props.resource,
    }
  }, [methods.control, methods.register, methods.setValue, methods.unregister, props.resource])
  const { handleSubmit } = methods

  const [setAppChannel, { loading: submitting }] = useSetAppChannelMutation({
    onCompleted: (data) => {
      if (data?.setAppChannel?.id) {
        methods.setValue('smtpHost', data.setAppChannel.value.smtpHost)
        methods.setValue('smtpPort', data.setAppChannel.value.smtpPort)
        methods.setValue('smtpUsername', data.setAppChannel.value.smtpUsername)
        methods.setValue('smtpPassword', data.setAppChannel.value.smtpPassword)
        methods.setValue('smtpFrom', data.setAppChannel.value.smtpFrom)
      }
    },
  })

  const onSubmit = useCallback(
    (values: any) => {
      console.log(values)
      setAppChannel({
        variables: {
          appId: props.id,
          channel: gql_Channel.Email,
          value: {
            smtpHost: values.smtpHost,
            smtpPort: values.smtpPort,
            smtpUsername: values.smtpUsername,
            smtpPassword: values.smtpPassword,
            emailFrom: values.emailFrom,
          },
        },
      })
    },
    [props.id, setAppChannel]
  )

  useEffect(() => {
    if (!loading && data?.appChannel?.id) {
      methods.setValue('smtpHost', data.appChannel.value.smtpHost)
      methods.setValue('smtpPort', data.appChannel.value.smtpPort)
      methods.setValue('smtpUsername', data.appChannel.value.smtpUsername)
      methods.setValue('smtpPassword', data.appChannel.value.smtpPassword)
      methods.setValue('emailFrom', data.appChannel.value.emailFrom)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, methods])

  return (
    <Section>
      <Skeleton isLoaded={!loading}>
        <Heading as="h2" size="md" mb={2}>
          Email
        </Heading>
      </Skeleton>

      <Skeleton isLoaded={!loading}>
        <Text my={2}>
          Configure the email channel to send emails to your users. You can use any SMTP server to
          send emails.
        </Text>
      </Skeleton>

      <chakra.form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateColumns="1fr 250px" gap={4}>
          <Skeleton isLoaded={!loading}>
            <ca.FormControl {...inputProps} source="smtpHost">
              <ca.FormLabel {...inputProps} htmlFor="email-channel-smtp-host" source="smtpHost" />
              <ca.Input {...inputProps} id="email-channel-smtp-host" source="smtpHost" />
            </ca.FormControl>
          </Skeleton>

          <Skeleton isLoaded={!loading}>
            <ca.FormControl {...inputProps} source="smtpPort">
              <ca.FormLabel {...inputProps} htmlFor="email-channel-smtp-port" source="smtpPort" />
              <ca.Input
                {...inputProps}
                id="email-channel-smtp-port"
                type="number"
                source="smtpPort"
              />
            </ca.FormControl>
          </Skeleton>
        </Grid>

        <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={4}>
          <Skeleton isLoaded={!loading}>
            <ca.FormControl {...inputProps} source="smtpUsername">
              <ca.FormLabel
                {...inputProps}
                htmlFor="email-channel-smtp-username"
                source="smtpUsername"
              />
              <ca.Input {...inputProps} id="email-channel-smtp-username" source="smtpUsername" />
            </ca.FormControl>
          </Skeleton>

          <Skeleton isLoaded={!loading}>
            <ca.FormControl {...inputProps} source="smtpPassword">
              <ca.FormLabel
                {...inputProps}
                htmlFor="email-channel-smtp-password"
                source="smtpPassword"
              />
              <ca.Input
                {...inputProps}
                id="email-channel-smtp-password"
                source="smtpPassword"
                type={data?.appChannel?.id ? 'password' : 'text'}
              />
            </ca.FormControl>
          </Skeleton>
        </Grid>

        <Skeleton isLoaded={!loading}>
          <ca.FormControl {...inputProps} source="emailFrom" mt={4}>
            <ca.FormLabel {...inputProps} htmlFor="email-channel-mail-from" source="emailFrom" />
            <ca.Input {...inputProps} id="email-channel-mail-from" source="emailFrom" />
          </ca.FormControl>
        </Skeleton>

        <Button
          type="submit"
          mt={4}
          size="sm"
          isDisabled={submitting || loading}
          isLoading={submitting}
        >
          Save
        </Button>
      </chakra.form>
    </Section>
  )
}

export const AppChannels: FC<any> = (props) => {
  return (
    <>
      <WebPushChannel {...props} />
      <EmailChannel {...props} />
    </>
  )
}

import React, { FC, useMemo } from 'react'
import { Box, Button, HStack, Icon, Text, IconButton, Skeleton } from '@chakra-ui/react'
import { BiChevronRight } from 'react-icons/bi'
import useBreadcrumbs, { BreadcrumbsRoute, Options } from 'use-react-router-breadcrumbs'
import { Link } from 'react-router-dom'
import { ca, Query, useAdminStateValue, useGetResourceLabel, useTranslate } from 'chakra-admin'
import { AiOutlineHome } from 'react-icons/ai'

type Props = {
  additionalRoutes?: BreadcrumbsRoute[]
}

export const HomeBreadcrumb: FC<any> = ({ match }) => {
  return (
    <Box display="flex" alignItems="center">
      <IconButton
        size="sm"
        as={Link}
        to={match.pathname}
        variant="ghost"
        h="32px"
        borderRadius="full"
        // colorScheme="primary"
        px={1}
        mr={-2}
        transform="translateX(-4px)"
        aria-label="Home"
        icon={<Icon as={AiOutlineHome} boxSize="22px" mb={1} />}
      />
    </Box>
  )
}

export const ButtonBreadcrumb: FC<any> = ({
  match,
  location,
  label,
  isLast: isLastProp,
  loading,
  data,
  ...props
}) => {
  const isLast = useMemo(
    () => (typeof isLastProp === 'boolean' ? isLastProp : match?.pathname === location?.pathname),
    [isLastProp, location?.pathname, match?.pathname]
  )

  return (
    <Box display="flex" alignItems="center" h="100%">
      <Skeleton isLoaded={!loading}>
        {isLast ? (
          <Text fontSize="sm" pt={0} lineHeight="32px" opacity={0.6}>
            {!loading ? label : ''}
          </Text>
        ) : (
          <Button
            size="sm"
            variant="ghost"
            fontWeight="medium"
            as={Link}
            h="32px"
            px={1}
            mx={-1}
            to={match.pathname}
          >
            {!loading ? label : ''}
          </Button>
        )}
      </Skeleton>
    </Box>
  )
}

export const ResourceBreadcrumb: FC<{ resource: string; match: any }> = ({
  resource,
  ...props
}) => {
  const getResourceLabel = useGetResourceLabel()

  return <ButtonBreadcrumb {...props} label={getResourceLabel(resource)} />
}

const ButtonBreadcrumbField = ca.f(ButtonBreadcrumb, { target: 'label' })

export const ResourceDetailsBreadcrumbItem: FC<any> = ({
  query,
  resource,
  fields,
  source,
  ...props
}) => {
  const t = useTranslate()

  const isShow = useMemo(
    () => props.location?.pathname?.indexOf('/show') > -1,
    [props.location?.pathname]
  )

  return (
    <Query
      query={query}
      resource={resource}
      type="show"
      fields={fields}
      variables={{ id: props.match?.params?.id }}
    >
      <ButtonBreadcrumbField {...props} source={source} isLast={isShow} label="Details" />

      {!isShow && (
        <>
          <BreadcrumbSeparator />
          <ButtonBreadcrumb isLast={true} {...props} label={t('ca.action.edit')} />
        </>
      )}
    </Query>
  )
}

export const BreadcrumbSeparator: FC = () => {
  return (
    <Box display="flex" alignItems="center" h="32px" opacity={0.6}>
      <Icon boxSize="18px" as={BiChevronRight} />
    </Box>
  )
}

const ADDITIONAL_ROUTES: BreadcrumbsRoute[] = [
  { path: '/', breadcrumb: HomeBreadcrumb },
  {
    path: '/App/:id',
    breadcrumb: ResourceDetailsBreadcrumbItem,
    props: {
      query: 'app',
      resource: 'App',
      source: 'name',
    },
  },
] as any

export const Breadcrumbs: FC<Props> = ({ additionalRoutes = ADDITIONAL_ROUTES }) => {
  const t = useTranslate()
  const getResourceLabel = useGetResourceLabel()
  const { initialized, registeredResources } = useAdminStateValue()

  const routes: BreadcrumbsRoute[] = useMemo(() => {
    const resourcesRoutes = Object.keys(registeredResources).reduce((acc, resource) => {
      acc.push({
        path: `/${resource}`,
        breadcrumb: ResourceBreadcrumb as any,
        props: {
          resource,
        },
      } as any)

      if (registeredResources[resource]?.hasCreate) {
        acc.push({
          path: `/${resource}/create`,
          breadcrumb: ButtonBreadcrumb as any,
          props: {
            label: t('ca.action.create', {
              count: 1,
              name: getResourceLabel(resource, 1),
            }),
          },
        } as any)
      }

      return acc
    }, [] as BreadcrumbsRoute[])

    return [...resourcesRoutes, ...additionalRoutes]
  }, [additionalRoutes, getResourceLabel, registeredResources, t])

  const options = useMemo<Options>(() => {
    return {
      excludePaths: Object.keys(registeredResources).map((r) => `/${r}/:id/show`),
    }
  }, [registeredResources])

  const breadcrumbs = useBreadcrumbs(routes, options)

  if (!initialized) {
    return null
  }

  return (
    <HStack spacing={1}>
      {breadcrumbs.map(({ match, breadcrumb, location }, index) => {
        return (
          <React.Fragment key={match.pathname}>
            {breadcrumb}
            {index < breadcrumbs.length - 1 && <BreadcrumbSeparator />}
          </React.Fragment>
        )
      })}
    </HStack>
  )
}

import { OperationVariables } from '@apollo/client'
import { CreateStrategy as DefaultCreateStrategy } from 'chakra-admin'
import { gql_User } from '../graphql'

export class CreateStrategy implements DefaultCreateStrategy {
  getMutationVariables(values: Record<string, any>): OperationVariables {
    switch (values.__typename) {
      case 'User': {
        const { __typename, channels, ...rest } = values as gql_User

        let newChannels = channels.map((c) => {
          const { details, ...rest } = c

          let newDetails = {}

          try {
            newDetails = JSON.parse(details)
          } catch (e) {
            console.error(e)
          }

          return {
            ...rest,
            details: newDetails,
          }
        })

        return {
          data: {
            ...rest,
            channels: newChannels,
          },
        }
      }
      default: {
        const { __typename, ...data } = values as any
        return {
          data,
        }
      }
    }
  }
}

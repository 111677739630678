import React, { FC } from 'react'
import { FormControl, FormLabel, Grid, HStack, Input, Stack, Text } from '@chakra-ui/react'
import { ca, useTranslate } from 'chakra-admin'

export const AppForm: FC<any> = (props) => {
  const t = useTranslate()
  return (
    <Stack spacing="4" mt={3} mb={20}>
      <Grid
        as={FormControl}
        templateColumns={{ base: 'unset', md: '1fr 2fr' }}
        templateRows={{ base: '1fr 1fr', md: 'unset' }}
        alignItems="baseline"
        columnGap="2"
        isRequired
      >
        <FormLabel htmlFor="name">{t(`resources.App.fields.name`)}</FormLabel>
        <Input
          {...props.register('name', {
            required: t('ca.message.required_field'),
          })}
          variant="outline"
          placeholder={t(`resources.App.placeholders.name`)}
          borderRadius="lg"
        />
      </Grid>

      <ca.FormControl {...props} source="active">
        <ca.FormLabel {...props} htmlFor="active">
          {t(`resources.App.fields.active`)}
        </ca.FormLabel>
        <HStack align="flex-start" spacing={4}>
          <ca.Switch {...props} source="active" colorScheme="blue" mt={0.5} />
          <Text fontSize="sm">L'applicazione può inviare notifiche</Text>
        </HStack>
      </ca.FormControl>
    </Stack>
  )
}

/* eslint-disable import/no-anonymous-default-export */
import { GiQueenCrown } from 'react-icons/gi'
import { AdminCreate } from './AdminCreate'
import { AdminEdit } from './AdminEdit'
import { AdminList } from './AdminList'

export default {
  icon: GiQueenCrown,
  name: 'Admin',
  list: AdminList,
  create: AdminCreate,
  edit: AdminEdit,
}

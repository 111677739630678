/* eslint-disable import/no-anonymous-default-export */
import { RiSendPlaneLine } from 'react-icons/ri'
import { NotificationCreate } from './NotificationCreate'
import { NotificationEdit } from './NotificationEdit'
import { NotificationList } from './NotificationList'

export default {
  icon: RiSendPlaneLine,
  name: 'Notification',
  list: NotificationList,
  create: NotificationCreate,
  edit: NotificationEdit,
}

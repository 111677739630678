import React, { FC, useMemo } from 'react'
import { Text } from '@chakra-ui/react'
import { ca } from 'chakra-admin'

export type Content = {
  notificationCenterTitle: string
  emailTemplateSubject: string
  emailTemplateText: string
  smsTemplate: string
  webPushTemplate: string
  notificationCenterEnabled: boolean
  emailEnabled: boolean
  smsEnabled: boolean
  webPushEnabled: boolean
}

type Props = {
  rawContent?: any
  notificationType?: any
}

const ContentRenderer: FC<{ content: Content }> = ({ content }) => {
  let text = useMemo(() => {
    if (!content) {
      return 'No content'
    }

    if (content.notificationCenterEnabled) {
      return content.notificationCenterTitle
    }

    if (content.emailEnabled) {
      return content.emailTemplateText
    }

    if (content.smsEnabled) {
      return content.smsTemplate
    }

    if (content.webPushEnabled) {
      return content.webPushTemplate
    }
  }, [content])

  return <Text ml={2}>{text}</Text>
}

export const NotificationContent: FC<Props> = ({ rawContent, notificationType }) => {
  return <ContentRenderer content={notificationType || rawContent} />
}

export const NotificationContentField = ca.f<Props>(NotificationContent)

import React, { FC } from 'react'
import { MUTATION_UPDATE_USER, QUERY_GET_USER } from '../../queries'
import { UserForm } from './UserForm'
import { Edit } from '../../components/details/Edit'
import { BaseForm } from '../../components/forms/BaseForm'

export const UserEdit: FC = (props) => {
  return (
    <Edit query={QUERY_GET_USER} mutation={MUTATION_UPDATE_USER} {...props}>
      <BaseForm {...props}>
        <UserForm isRequired={false} />
      </BaseForm>
    </Edit>
  )
}

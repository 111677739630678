import React, { FC } from 'react'
import { MUTATION_CREATE_ADMIN } from '../../queries'
import { BaseForm } from '../../components/forms/BaseForm'
import { AdminForm } from './AdminForm'
import { Create } from '../../components/details/Create'

export const AdminCreate: FC = (props) => {
  return (
    <Create mutation={MUTATION_CREATE_ADMIN} {...props}>
      <BaseForm>
        <AdminForm {...props} />
      </BaseForm>
    </Create>
  )
}

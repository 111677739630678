import React, { FC } from 'react'
import { MUTATION_UPDATE_APP, QUERY_GET_APP } from '../../queries'
import { AppForm } from './AppForm'
import { Edit } from '../../components/details/Edit'
import { BaseForm } from '../../components/forms/BaseForm'

export const AppEdit: FC = (props) => {
  return (
    <Edit query={QUERY_GET_APP} mutation={MUTATION_UPDATE_APP} {...props}>
      <BaseForm {...props}>
        <AppForm isRequired={false} />
      </BaseForm>
    </Edit>
  )
}

import React, { FC } from 'react'
import { MUTATION_CREATE_NOTIFICATION } from '../../queries'
import { BaseForm } from '../../components/forms/BaseForm'
import { NotificationForm } from './NotificationForm'
import { Create } from '../../components/details/Create'

export const NotificationCreate: FC = (props) => {
  return <div>TODO</div>
  // return (
  //   <Create mutation={MUTATION_CREATE_NOTIFICATION} {...props}>
  //     <BaseForm>
  //       <NotificationForm {...props} />
  //     </BaseForm>
  //   </Create>
  // )
}

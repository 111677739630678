import { Box, FormControl, FormHelperText, FormLabel, Grid, Input, Stack } from '@chakra-ui/react'
import { useTranslate } from 'chakra-admin'
import React, { FC } from 'react'

export const AdminForm: FC<any> = ({ isEdit, ...props }) => {
  const t = useTranslate()
  return (
    <Stack spacing="4" mt={3} mb={20}>
      <Grid
        as={FormControl}
        templateColumns={{ base: 'unset', md: '1fr 2fr' }}
        templateRows={{ base: '1fr 1fr', md: 'unset' }}
        alignItems="baseline"
        columnGap="2"
        isRequired={!isEdit}
      >
        <FormLabel htmlFor="firstName">{t(`resources.Admin.fields.firstName`)}</FormLabel>
        <Input
          {...props.register('firstName', {
            required: !isEdit && t('ca.message.required_field'),
          })}
          variant="outline"
          placeholder={t(`resources.Admin.placeholders.firstName`)}
          borderRadius="lg"
        />
      </Grid>
      <Grid
        as={FormControl}
        templateColumns={{ base: 'unset', md: '1fr 2fr' }}
        templateRows={{ base: '1fr 1fr', md: 'unset' }}
        alignItems="baseline"
        columnGap="2"
      >
        <FormLabel htmlFor="lastName">{t(`resources.Admin.fields.lastName`)}</FormLabel>
        <Input
          variant="outline"
          {...props.register('lastName')}
          placeholder={t(`resources.Admin.placeholders.lastName`)}
          borderRadius="lg"
        />
      </Grid>
      <Grid
        as={FormControl}
        templateColumns={{ base: 'unset', md: '1fr 2fr' }}
        templateRows={{ base: '1fr 1fr', md: 'unset' }}
        alignItems="baseline"
        columnGap="2"
        isRequired={!isEdit}
      >
        <FormLabel htmlFor="email">{t(`resources.Admin.fields.email`)}</FormLabel>
        <Input
          variant="outline"
          {...props.register('email', { required: !isEdit && t('ca.message.required_field') })}
          placeholder={t(`resources.Admin.placeholders.email`)}
          borderRadius="lg"
        />
      </Grid>
      <Grid
        as={FormControl}
        templateColumns={{ base: 'unset', md: '1fr 2fr' }}
        templateRows={{ base: '1fr 1fr', md: 'unset' }}
        alignItems="baseline"
        columnGap="2"
        isRequired={!isEdit}
      >
        <FormLabel htmlFor="password">{t(`resources.Admin.fields.password`)}</FormLabel>
        <Box>
          <Input
            variant="outline"
            {...props.register('password', { required: !isEdit && t('ca.message.required_field') })}
            placeholder={t(`resources.Admin.placeholders.password`)}
            borderRadius="lg"
          />
          {isEdit && (
            <FormHelperText ml={2}>
              Da riempire solamente se si vuole modificare la password
            </FormHelperText>
          )}
        </Box>
      </Grid>
    </Stack>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import {
  DocumentNode,
  gql,
  OperationVariables,
  QueryResult,
  TypedDocumentNode,
} from '@apollo/client'
import { generateFields, DefaultShowStrategy } from 'chakra-admin'
import { query } from 'gql-query-builder'
import omit from 'lodash.omit'
import { gql_Admin, gql_App, gql_NotificationType, gql_User } from '../graphql'
import { formatDate, resolvePicture } from './utils'

export class EditStrategy extends DefaultShowStrategy {
  getQuery(
    resource: string,
    operation: string,
    variables?: OperationVariables,
    fields?: string[]
  ): DocumentNode | TypedDocumentNode<any, OperationVariables> {
    const result = query({
      operation,
      variables: {
        id: {
          value: variables?.id,
          type: 'ID',
          required: true,
        },
      },
      fields: generateFields(fields),
    })

    return gql(result.query)
  }

  getItem = (({ data }: QueryResult<any, OperationVariables>): any => {
    const toReturnData =
      data && Object.keys(data).length > 0 && (data as any)[Object.keys(data)[0]]
        ? (data as any)[Object.keys(data)[0]]
        : {}

    switch (toReturnData.__typename) {
      case 'Admin': {
        const { ...rest } = toReturnData as gql_Admin

        return {
          ...rest,
        }
      }

      case 'User': {
        const { channels: inputChannels, ...rest } = toReturnData as gql_User

        const channels = inputChannels.map((c) => {
          const { details, ...rest } = c

          return {
            ...rest,
            details: JSON.stringify(details, null, 2),
          }
        })

        return {
          ...rest,
          channels,
        }
      }

      default:
        return toReturnData
    }
  }) as any

  getItemVariables = (id: string): OperationVariables => {
    return { id }
  }

  getMutationVariables = (id: string, values: Record<string, any>): OperationVariables => {
    switch (values.__typename) {
      case 'Admin': {
        const { __typename, ...rest } = values as gql_Admin

        return {
          id,
          data: {
            ...omit(rest, 'id'),
            // picture: resolvePicture(picture),
          },
        }
      }
      case 'App': {
        const {
          __typename,
          hasApiKey,
          updatedAt,
          createdAt,
          apiKeyCreatedAt,
          enabledOrigin,
          hasVapidKeys,
          vapidKeysCreatedAt,
          vapidSignatureMailto,
          vapidPublicKey,
          ...rest
        } = values as gql_App

        return {
          id,
          data: {
            ...omit(rest, 'id'),
            // picture: resolvePicture(picture),
          },
        }
      }

      case 'NotificationType': {
        const { __typename, updatedAt, createdAt, emailTemplateHtmlPath, appId, ...rest } =
          values as gql_NotificationType

        return {
          id,
          data: {
            ...omit(rest, 'id', 'app'),
          },
        }
      }

      default:
        const { id: iId, __typename, createdAt, updatedAt, ...data } = values as any

        console.log('data', data)

        return {
          id,
          data,
        }
    }
  }
}

import { AuthProvider, DefaultUserIdentity } from 'chakra-admin'
import { ApolloClient } from '@apollo/client'
import { MUTATION_LOGIN, MUTATION_LOGOUT, QUERY_GET_ADMIN_ME } from './queries'
import { client } from './apolloClient'

import {
  gql_GetAdminMeQuery,
  gql_LoginMutation,
  gql_LoginMutationVariables,
  gql_LogoutMutation,
} from './graphql'

export class MashupAuthProvider implements AuthProvider {
  client!: ApolloClient<any>

  init() {
    this.client = client
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async login(params: Record<string, any>) {
    try {
      this.client.resetStore()
      const result = await this.client.mutate<gql_LoginMutation, gql_LoginMutationVariables>({
        mutation: MUTATION_LOGIN,
        variables: {
          credentials: {
            email: params.email,
            password: params.password,
          },
        },
      })

      if (result.data?.login?.user?.id) {
        // eslint-disable-next-line @typescript-eslint/return-await
        return await Promise.resolve()
      } else {
        // eslint-disable-next-line @typescript-eslint/return-await
        return await Promise.reject()
      }
    } catch (e) {
      return Promise.reject()
    }
  }

  async checkError(params: { status?: number }): Promise<void> {
    if (params.status === 401 || params.status === 403) {
      return Promise.reject()
    }

    Promise.resolve()
  }

  async checkAuth() {
    const result = await this.client.query<gql_GetAdminMeQuery>({
      query: QUERY_GET_ADMIN_ME,
      fetchPolicy: 'cache-first',
    })

    if (result?.data?.adminMe?.id) {
      return
    }

    throw new Error('Auth error')
  }

  async logout() {
    try {
      const result = await this.client.mutate<gql_LogoutMutation>({
        mutation: MUTATION_LOGOUT,
      })

      if (result.data?.logout) {
        this.init()
        return await Promise.resolve()
      } else {
        return await Promise.reject()
      }
    } catch (e) {
      return Promise.reject()
    }
  }

  async getIdentity(): Promise<DefaultUserIdentity> {
    const result = await this.client.query<gql_GetAdminMeQuery>({
      query: QUERY_GET_ADMIN_ME,
      fetchPolicy: 'cache-first',
    })
    if (result.data?.adminMe?.id) {
      return {
        id: result.data.adminMe.id,
        username: result.data.adminMe.email!,
        // fullName: `${result.data.adminMe.firstName || ''} ${result.data.adminMe.lastName}`,
        fullName: result.data.adminMe.lastName,
        // todo avatarUrl
        avatarUrl: 'https://pbs.twimg.com/profile_images/745236379228069888/5Zre6uSy_400x400.jpg',
        // avatarUrl: result?.data?.adminMe?.picture?.urlSmall,
        // roles: result.data.adminMe.roles,
      } as any
    }

    throw new Error('User not found')
  }
}

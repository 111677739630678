import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * Implement the DateTime<FixedOffset> scalar
   *
   * The input/output is a string in RFC3339 format.
   */
  DateTime: any;
  /** A scalar that can represent any JSON value. */
  JSON: any;
  /**
   * A UUID is a unique 128-bit number, stored as 16 octets. UUIDs are parsed as
   * Strings within GraphQL. UUIDs are used to assign unique identifiers to
   * entities without requiring a central allocating authority.
   *
   * # References
   *
   * * [Wikipedia: Universally Unique Identifier](http://en.wikipedia.org/wiki/Universally_unique_identifier)
   * * [RFC4122: A Universally Unique IDentifier (UUID) URN Namespace](http://tools.ietf.org/html/rfc4122)
   */
  UUID: any;
};

export type gql_Admin = {
  __typename?: 'Admin';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type gql_AdminConnection = {
  __typename?: 'AdminConnection';
  /** A list of edges. */
  edges: Array<gql_AdminEdge>;
  /** A list of nodes. */
  nodes: Array<gql_Admin>;
  /** Information to aid in pagination. */
  pageInfo: gql_PageInfo;
};

/** An edge in a connection. */
export type gql_AdminEdge = {
  __typename?: 'AdminEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: gql_Admin;
};

export type gql_AdminFilters = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export enum gql_AdminSortByKeys {
  Email = 'email',
  Id = 'id'
}

export type gql_App = {
  __typename?: 'App';
  active: Scalars['Boolean'];
  apiKeyCreatedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  enabledOrigin?: Maybe<Scalars['String']>;
  hasApiKey: Scalars['Boolean'];
  hasVapidKeys: Scalars['Boolean'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vapidKeysCreatedAt?: Maybe<Scalars['DateTime']>;
  vapidPublicKey?: Maybe<Scalars['String']>;
  vapidSignatureMailto?: Maybe<Scalars['String']>;
};

export type gql_AppChannel = {
  __typename?: 'AppChannel';
  appId: Scalars['UUID'];
  channel: gql_Channel;
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['JSON'];
};

export type gql_AppConnection = {
  __typename?: 'AppConnection';
  /** A list of edges. */
  edges: Array<gql_AppEdge>;
  /** A list of nodes. */
  nodes: Array<gql_App>;
  /** Information to aid in pagination. */
  pageInfo: gql_PageInfo;
};

/** An edge in a connection. */
export type gql_AppEdge = {
  __typename?: 'AppEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: gql_App;
};

export type gql_AppFilters = {
  ids?: InputMaybe<Array<Scalars['UUID']>>;
  name?: InputMaybe<Scalars['String']>;
};

export enum gql_AppSortByKeys {
  Id = 'id',
  Name = 'name'
}

export enum gql_Channel {
  Email = 'EMAIL',
  Sms = 'SMS',
  WebPush = 'WEB_PUSH'
}

export type gql_CreateAdminData = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type gql_CreateAppData = {
  active?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type gql_CreateNotificationData = {
  rawChannels?: InputMaybe<Array<gql_NotificationRawChannelInput>>;
  rawContent?: InputMaybe<gql_NotificationContent>;
  recipientIds?: InputMaybe<Array<Scalars['ID']>>;
  recipientRefIds?: InputMaybe<Array<Scalars['ID']>>;
  replacements?: InputMaybe<Scalars['JSON']>;
  scheduledFor?: InputMaybe<Scalars['DateTime']>;
  senderIcon?: InputMaybe<Scalars['String']>;
  senderId?: InputMaybe<Scalars['ID']>;
  senderName?: InputMaybe<Scalars['String']>;
  senderRefId?: InputMaybe<Scalars['ID']>;
  typeId?: InputMaybe<Scalars['ID']>;
  typeSlug?: InputMaybe<Scalars['String']>;
};

export type gql_CreateNotificationTypeData = {
  active?: InputMaybe<Scalars['Boolean']>;
  appId: Scalars['UUID'];
  description?: InputMaybe<Scalars['String']>;
  emailEnabled?: InputMaybe<Scalars['Boolean']>;
  emailTemplateSubject?: InputMaybe<Scalars['String']>;
  emailTemplateText?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notificationCenterBody?: InputMaybe<Scalars['String']>;
  notificationCenterClickUrl?: InputMaybe<Scalars['String']>;
  notificationCenterEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationCenterIcon?: InputMaybe<Scalars['String']>;
  notificationCenterTitle?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  smsEnabled?: InputMaybe<Scalars['Boolean']>;
  smsTemplate?: InputMaybe<Scalars['String']>;
  tempEmailTemplateHtml?: InputMaybe<Scalars['String']>;
  webPushEnabled?: InputMaybe<Scalars['Boolean']>;
  webPushTemplate?: InputMaybe<Scalars['JSON']>;
};

export type gql_CreateUserChannelData = {
  active: Scalars['Boolean'];
  channel: gql_Channel;
  details: Scalars['JSON'];
  userId: Scalars['UUID'];
};

export type gql_CreateUserChannelInput = {
  active: Scalars['Boolean'];
  channel: gql_Channel;
  details: Scalars['JSON'];
};

export type gql_CreateUserData = {
  appId?: InputMaybe<Scalars['ID']>;
  channels: Array<gql_CreateUserChannelInput>;
  refId: Scalars['ID'];
};

export type gql_Credentials = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type gql_GeneratedApiKey = {
  __typename?: 'GeneratedApiKey';
  /** The private API key for the app visible only once */
  apiKey: Scalars['String'];
};

export type gql_GeneratedVapidKeys = {
  __typename?: 'GeneratedVapidKeys';
  /** The public VAPID key */
  publicKey: Scalars['String'];
};

export type gql_InboxFilters = {
  unreadOnly?: InputMaybe<Scalars['Boolean']>;
};

export enum gql_InboxSortByKeys {
  Id = 'id'
}

export type gql_LoginResult = {
  __typename?: 'LoginResult';
  user: gql_Admin;
};

export type gql_Mutation = {
  __typename?: 'Mutation';
  createAdmin: gql_Admin;
  createApp: gql_App;
  createNotification: gql_Notification;
  createNotificationType: gql_NotificationType;
  createUser: gql_User;
  createUserChannel: gql_UserChannel;
  deleteAdmin: Scalars['Boolean'];
  deleteApp: Scalars['Boolean'];
  deleteNotification: Scalars['Boolean'];
  deleteNotificationType: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  deleteUserChannel: Scalars['Boolean'];
  destroyApiKey: Scalars['Boolean'];
  destroyVapidKeys: Scalars['Boolean'];
  generateApiKey: gql_GeneratedApiKey;
  /**
   * * Generate a new user token to fetch notifications from client
   *      * * `appId` - Don't use this from a client
   *      * * `id` - Generate token by user id
   *      * * `refId` - Generate token by user ref_id
   */
  generateUserToken: Scalars['String'];
  generateVapidKeys: gql_GeneratedVapidKeys;
  hc_deleteChannel: Scalars['Boolean'];
  hc_markAsRead: Scalars['Boolean'];
  hc_setChannel: gql_UserChannel;
  /** * Admin login */
  login: gql_LoginResult;
  /** * Admin logout */
  logout: Scalars['Boolean'];
  resetEnabledOrigin: Scalars['Boolean'];
  resetVapidSignatureMailto: Scalars['Boolean'];
  saveUser: gql_User;
  send: gql_Notification;
  setAppChannel: gql_AppChannel;
  setEnabledOrigin: Scalars['String'];
  setVapidSignatureMailto: Scalars['String'];
  updateAdmin: gql_Admin;
  updateApp: gql_App;
  updateNotificationType: gql_NotificationType;
  updateUser: gql_User;
  updateUserChannel: gql_UserChannel;
};


export type gql_MutationCreateAdminArgs = {
  data: gql_CreateAdminData;
};


export type gql_MutationCreateAppArgs = {
  data: gql_CreateAppData;
};


export type gql_MutationCreateNotificationArgs = {
  appId?: InputMaybe<Scalars['String']>;
  data: gql_CreateNotificationData;
};


export type gql_MutationCreateNotificationTypeArgs = {
  data: gql_CreateNotificationTypeData;
};


export type gql_MutationCreateUserArgs = {
  data: gql_CreateUserData;
};


export type gql_MutationCreateUserChannelArgs = {
  data: gql_CreateUserChannelData;
};


export type gql_MutationDeleteAdminArgs = {
  id: Scalars['ID'];
};


export type gql_MutationDeleteAppArgs = {
  id: Scalars['ID'];
};


export type gql_MutationDeleteNotificationArgs = {
  id: Scalars['ID'];
};


export type gql_MutationDeleteNotificationTypeArgs = {
  id: Scalars['ID'];
};


export type gql_MutationDeleteUserArgs = {
  appId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  refId?: InputMaybe<Scalars['ID']>;
};


export type gql_MutationDeleteUserChannelArgs = {
  id: Scalars['ID'];
};


export type gql_MutationDestroyApiKeyArgs = {
  appId: Scalars['ID'];
};


export type gql_MutationDestroyVapidKeysArgs = {
  appId: Scalars['ID'];
};


export type gql_MutationGenerateApiKeyArgs = {
  appId: Scalars['ID'];
};


export type gql_MutationGenerateUserTokenArgs = {
  appId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  refId?: InputMaybe<Scalars['ID']>;
};


export type gql_MutationGenerateVapidKeysArgs = {
  appId: Scalars['ID'];
};


export type gql_MutationHc_DeleteChannelArgs = {
  channel: gql_Channel;
};


export type gql_MutationHc_MarkAsReadArgs = {
  inboxIds: Array<Scalars['UUID']>;
};


export type gql_MutationHc_SetChannelArgs = {
  active: Scalars['Boolean'];
  channel: gql_Channel;
  details: Scalars['JSON'];
};


export type gql_MutationLoginArgs = {
  credentials: gql_Credentials;
};


export type gql_MutationResetEnabledOriginArgs = {
  appId: Scalars['ID'];
};


export type gql_MutationResetVapidSignatureMailtoArgs = {
  appId: Scalars['ID'];
};


export type gql_MutationSaveUserArgs = {
  data: gql_SaveUserData;
  id?: InputMaybe<Scalars['ID']>;
  refId?: InputMaybe<Scalars['ID']>;
};


export type gql_MutationSendArgs = {
  appId?: InputMaybe<Scalars['ID']>;
  forceSend?: InputMaybe<Scalars['Boolean']>;
  notification?: InputMaybe<gql_CreateNotificationData>;
  notificationId?: InputMaybe<Scalars['ID']>;
};


export type gql_MutationSetAppChannelArgs = {
  appId: Scalars['ID'];
  channel: gql_Channel;
  value: Scalars['JSON'];
};


export type gql_MutationSetEnabledOriginArgs = {
  appId: Scalars['ID'];
  origin: Scalars['String'];
};


export type gql_MutationSetVapidSignatureMailtoArgs = {
  appId: Scalars['ID'];
  mailto: Scalars['String'];
};


export type gql_MutationUpdateAdminArgs = {
  data: gql_UpdateAdminData;
  id: Scalars['ID'];
};


export type gql_MutationUpdateAppArgs = {
  data: gql_UpdateAppData;
  id: Scalars['ID'];
};


export type gql_MutationUpdateNotificationTypeArgs = {
  data: gql_UpdateNotificationTypeData;
  id: Scalars['ID'];
};


export type gql_MutationUpdateUserArgs = {
  data: gql_UpdateUserData;
  id?: InputMaybe<Scalars['ID']>;
  refId?: InputMaybe<Scalars['ID']>;
};


export type gql_MutationUpdateUserChannelArgs = {
  data: gql_UpdateUserChannelData;
  id: Scalars['ID'];
};

export type gql_Notification = {
  __typename?: 'Notification';
  addedToQueueAt?: Maybe<Scalars['DateTime']>;
  appId: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  notificationType?: Maybe<gql_NotificationType>;
  rawChannels?: Maybe<Scalars['JSON']>;
  rawContent?: Maybe<Scalars['JSON']>;
  recipients: Array<gql_NotificationRecipient>;
  replacements?: Maybe<Scalars['JSON']>;
  scheduledFor?: Maybe<Scalars['DateTime']>;
  senderIcon?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['UUID']>;
  senderName?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['UUID']>;
  updatedAt: Scalars['DateTime'];
};

export type gql_NotificationConnection = {
  __typename?: 'NotificationConnection';
  /** A list of edges. */
  edges: Array<gql_NotificationEdge>;
  /** A list of nodes. */
  nodes: Array<gql_Notification>;
  /** Information to aid in pagination. */
  pageInfo: gql_PageInfo;
};

export type gql_NotificationContent = {
  emailEnabled?: InputMaybe<Scalars['Boolean']>;
  emailHtml?: InputMaybe<Scalars['String']>;
  emailSubject?: InputMaybe<Scalars['String']>;
  emailText?: InputMaybe<Scalars['String']>;
  notificationCenterBody?: InputMaybe<Scalars['String']>;
  notificationCenterClickUrl?: InputMaybe<Scalars['String']>;
  notificationCenterEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationCenterIcon?: InputMaybe<Scalars['String']>;
  notificationCenterTitle?: InputMaybe<Scalars['String']>;
  smsEnabled?: InputMaybe<Scalars['Boolean']>;
  smsText?: InputMaybe<Scalars['String']>;
  webPushBadge?: InputMaybe<Scalars['String']>;
  webPushBody?: InputMaybe<Scalars['String']>;
  webPushClickUrl?: InputMaybe<Scalars['String']>;
  webPushData?: InputMaybe<Scalars['JSON']>;
  webPushDir?: InputMaybe<Scalars['String']>;
  webPushEnabled?: InputMaybe<Scalars['Boolean']>;
  webPushIcon?: InputMaybe<Scalars['String']>;
  webPushImage?: InputMaybe<Scalars['String']>;
  webPushLang?: InputMaybe<Scalars['String']>;
  webPushTag?: InputMaybe<Scalars['String']>;
  webPushTimestamp?: InputMaybe<Scalars['Int']>;
  webPushTitle?: InputMaybe<Scalars['String']>;
};

/** An edge in a connection. */
export type gql_NotificationEdge = {
  __typename?: 'NotificationEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: gql_Notification;
};

export type gql_NotificationFilters = {
  addedToQueueAt?: InputMaybe<Scalars['DateTime']>;
  appId?: InputMaybe<Scalars['ID']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  scheduledFor?: InputMaybe<Scalars['DateTime']>;
  senderId?: InputMaybe<Scalars['ID']>;
  typeId?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type gql_NotificationInbox = {
  __typename?: 'NotificationInbox';
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  notificationId: Scalars['UUID'];
  readAt?: Maybe<Scalars['DateTime']>;
  senderId?: Maybe<Scalars['UUID']>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['UUID'];
  value: Scalars['JSON'];
};

export type gql_NotificationInboxConnection = {
  __typename?: 'NotificationInboxConnection';
  /** A list of edges. */
  edges: Array<gql_NotificationInboxEdge>;
  /** A list of nodes. */
  nodes: Array<gql_NotificationInbox>;
  /** Information to aid in pagination. */
  pageInfo: gql_PageInfo;
};

/** An edge in a connection. */
export type gql_NotificationInboxEdge = {
  __typename?: 'NotificationInboxEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: gql_NotificationInbox;
};

export type gql_NotificationRawChannelInput = {
  channel: gql_Channel;
  value: Scalars['JSON'];
};

export type gql_NotificationRecipient = {
  __typename?: 'NotificationRecipient';
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  notificationId?: Maybe<Scalars['UUID']>;
  readAt?: Maybe<Scalars['DateTime']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['UUID']>;
};

export enum gql_NotificationSortByKeys {
  Id = 'id'
}

export type gql_NotificationType = {
  __typename?: 'NotificationType';
  active: Scalars['Boolean'];
  app?: Maybe<gql_App>;
  appId: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  emailEnabled: Scalars['Boolean'];
  emailTemplateHtmlPath?: Maybe<Scalars['String']>;
  emailTemplateSubject?: Maybe<Scalars['String']>;
  emailTemplateText?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  notificationCenterBody?: Maybe<Scalars['String']>;
  notificationCenterClickUrl?: Maybe<Scalars['String']>;
  notificationCenterEnabled: Scalars['Boolean'];
  notificationCenterIcon?: Maybe<Scalars['String']>;
  notificationCenterTitle?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  smsEnabled: Scalars['Boolean'];
  smsTemplate?: Maybe<Scalars['String']>;
  tempEmailTemplateHtml?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  webPushEnabled: Scalars['Boolean'];
  webPushTemplate?: Maybe<Scalars['JSON']>;
};

export type gql_NotificationTypeConnection = {
  __typename?: 'NotificationTypeConnection';
  /** A list of edges. */
  edges: Array<gql_NotificationTypeEdge>;
  /** A list of nodes. */
  nodes: Array<gql_NotificationType>;
  /** Information to aid in pagination. */
  pageInfo: gql_PageInfo;
};

/** An edge in a connection. */
export type gql_NotificationTypeEdge = {
  __typename?: 'NotificationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: gql_NotificationType;
};

export type gql_NotificationTypeFilters = {
  appId?: InputMaybe<Scalars['UUID']>;
  ids?: InputMaybe<Array<Scalars['UUID']>>;
  name?: InputMaybe<Scalars['String']>;
};

export enum gql_NotificationTypeSortByKeys {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

/** Information about pagination in a connection */
export type gql_PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type gql_Query = {
  __typename?: 'Query';
  admin?: Maybe<gql_Admin>;
  adminMe?: Maybe<gql_Admin>;
  admins: gql_AdminConnection;
  adminsCount: Scalars['Int'];
  app?: Maybe<gql_App>;
  appChannel?: Maybe<gql_AppChannel>;
  apps: gql_AppConnection;
  appsCount: Scalars['Int'];
  hc_inbox: gql_NotificationInboxConnection;
  hc_unreadCount: Scalars['Int'];
  hc_userMe?: Maybe<gql_User>;
  notification?: Maybe<gql_Notification>;
  notificationType?: Maybe<gql_NotificationType>;
  notificationTypes: gql_NotificationTypeConnection;
  notificationTypesCount: Scalars['Int'];
  notifications: gql_NotificationConnection;
  notificationsCount: Scalars['Int'];
  user?: Maybe<gql_User>;
  userChannel?: Maybe<gql_UserChannel>;
  userChannels: gql_UserChannelConnection;
  userChannelsCount: Scalars['Int'];
  users: gql_UserConnection;
  usersCount: Scalars['Int'];
};


export type gql_QueryAdminArgs = {
  id: Scalars['ID'];
};


export type gql_QueryAdminsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<gql_AdminFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  revert?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<gql_AdminSortByKeys>;
};


export type gql_QueryAppArgs = {
  id: Scalars['ID'];
};


export type gql_QueryAppChannelArgs = {
  appId: Scalars['ID'];
  channel: gql_Channel;
};


export type gql_QueryAppsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<gql_AppFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  revert?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<gql_AppSortByKeys>;
};


export type gql_QueryHc_InboxArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<gql_InboxFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  revert?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<gql_InboxSortByKeys>;
};


export type gql_QueryNotificationArgs = {
  id: Scalars['ID'];
};


export type gql_QueryNotificationTypeArgs = {
  id: Scalars['ID'];
};


export type gql_QueryNotificationTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<gql_NotificationTypeFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  revert?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<gql_NotificationTypeSortByKeys>;
};


export type gql_QueryNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<gql_NotificationFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  revert?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<gql_NotificationSortByKeys>;
};


export type gql_QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
  refId?: InputMaybe<Scalars['ID']>;
};


export type gql_QueryUserChannelArgs = {
  id: Scalars['ID'];
};


export type gql_QueryUserChannelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<gql_UserChannelFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  revert?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<gql_UserChannelSortByKeys>;
};


export type gql_QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<gql_UserFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  revert?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<gql_UserSortByKeys>;
};


export type gql_QueryUsersCountArgs = {
  filters?: InputMaybe<gql_UserFilters>;
};

export type gql_SaveUserData = {
  appId?: InputMaybe<Scalars['ID']>;
  channels?: InputMaybe<Array<gql_UpdateUserChannelInput>>;
  refId?: InputMaybe<Scalars['ID']>;
};

export type gql_UpdateAdminData = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type gql_UpdateAppData = {
  active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type gql_UpdateNotificationTypeData = {
  active?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  emailEnabled?: InputMaybe<Scalars['Boolean']>;
  emailTemplateSubject?: InputMaybe<Scalars['String']>;
  emailTemplateText?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notificationCenterBody?: InputMaybe<Scalars['String']>;
  notificationCenterClickUrl?: InputMaybe<Scalars['String']>;
  notificationCenterEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationCenterIcon?: InputMaybe<Scalars['String']>;
  notificationCenterTitle?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  smsEnabled?: InputMaybe<Scalars['Boolean']>;
  smsTemplate?: InputMaybe<Scalars['String']>;
  tempEmailTemplateHtml?: InputMaybe<Scalars['String']>;
  webPushEnabled?: InputMaybe<Scalars['Boolean']>;
  webPushTemplate?: InputMaybe<Scalars['JSON']>;
};

export type gql_UpdateUserChannelData = {
  active?: InputMaybe<Scalars['Boolean']>;
  details?: InputMaybe<Scalars['JSON']>;
};

export type gql_UpdateUserChannelInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  channel?: InputMaybe<gql_Channel>;
  details?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  toDelete?: InputMaybe<Scalars['Boolean']>;
};

export type gql_UpdateUserData = {
  channels?: InputMaybe<Array<gql_UpdateUserChannelInput>>;
  refId?: InputMaybe<Scalars['ID']>;
};

export type gql_User = {
  __typename?: 'User';
  app?: Maybe<gql_App>;
  appId: Scalars['UUID'];
  channels: Array<gql_UserChannel>;
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  refId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type gql_UserChannel = {
  __typename?: 'UserChannel';
  active: Scalars['Boolean'];
  channel: gql_Channel;
  createdAt: Scalars['DateTime'];
  details: Scalars['JSON'];
  id: Scalars['UUID'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['UUID'];
};

export type gql_UserChannelConnection = {
  __typename?: 'UserChannelConnection';
  /** A list of edges. */
  edges: Array<gql_UserChannelEdge>;
  /** A list of nodes. */
  nodes: Array<gql_UserChannel>;
  /** Information to aid in pagination. */
  pageInfo: gql_PageInfo;
};

/** An edge in a connection. */
export type gql_UserChannelEdge = {
  __typename?: 'UserChannelEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: gql_UserChannel;
};

export type gql_UserChannelFilters = {
  active?: InputMaybe<Scalars['Boolean']>;
  channel?: InputMaybe<gql_Channel>;
  userId?: InputMaybe<Scalars['UUID']>;
};

export enum gql_UserChannelSortByKeys {
  CreatedAt = 'createdAt',
  Id = 'id'
}

export type gql_UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges: Array<gql_UserEdge>;
  /** A list of nodes. */
  nodes: Array<gql_User>;
  /** Information to aid in pagination. */
  pageInfo: gql_PageInfo;
};

/** An edge in a connection. */
export type gql_UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: gql_User;
};

export type gql_UserFilters = {
  appId?: InputMaybe<Scalars['UUID']>;
  refId?: InputMaybe<Scalars['ID']>;
};

export enum gql_UserSortByKeys {
  CreatedAt = 'createdAt',
  Id = 'id',
  RefId = 'refId'
}

export type gql_AdminFragmentFragment = { __typename?: 'Admin', id: any, email: string, firstName?: string, lastName?: string, createdAt: any, updatedAt: any };

export type gql_AppFragmentFragment = { __typename?: 'App', id: any, name: string, active: boolean, hasApiKey: boolean, apiKeyCreatedAt?: any, hasVapidKeys: boolean, vapidPublicKey?: string, vapidSignatureMailto?: string, vapidKeysCreatedAt?: any, enabledOrigin?: string, createdAt: any, updatedAt: any };

export type gql_AppChannelFragmentFragment = { __typename?: 'AppChannel', id: any, appId: any, channel: gql_Channel, value: any };

export type gql_UserFragmentFragment = { __typename?: 'User', id: any, appId: any, refId: string, createdAt: any, updatedAt: any, channels: Array<{ __typename?: 'UserChannel', id: any, userId: any, channel: gql_Channel, details: any, active: boolean, createdAt: any }>, app?: { __typename?: 'App', id: any, name: string } };

export type gql_NotificationTypeFragmentFragment = { __typename?: 'NotificationType', id: any, appId: any, name: string, slug: string, description: string, notificationCenterTitle?: string, notificationCenterBody?: string, notificationCenterIcon?: string, notificationCenterClickUrl?: string, emailTemplateSubject?: string, emailTemplateText?: string, emailTemplateHtmlPath?: string, tempEmailTemplateHtml?: string, smsTemplate?: string, webPushTemplate?: any, notificationCenterEnabled: boolean, emailEnabled: boolean, smsEnabled: boolean, webPushEnabled: boolean, active: boolean, createdAt: any, updatedAt: any, app?: { __typename?: 'App', id: any, name: string } };

export type gql_NotificationFragmentFragment = { __typename?: 'Notification', id: any, appId: any, senderId?: any, typeId?: any, rawContent?: any, replacements?: any, addedToQueueAt?: any, rawChannels?: any, scheduledFor?: any, createdAt: any, updatedAt: any, notificationType?: { __typename?: 'NotificationType', id: any, name: string, notificationCenterTitle?: string, emailTemplateSubject?: string, emailTemplateText?: string, emailTemplateHtmlPath?: string, tempEmailTemplateHtml?: string, smsTemplate?: string, webPushTemplate?: any, notificationCenterEnabled: boolean, emailEnabled: boolean, smsEnabled: boolean, webPushEnabled: boolean } };

export type gql_GetAdminMeQueryVariables = Exact<{ [key: string]: never; }>;


export type gql_GetAdminMeQuery = { __typename?: 'Query', adminMe?: { __typename?: 'Admin', id: any, email: string, firstName?: string, lastName?: string, createdAt: any, updatedAt: any } };

export type gql_LoginMutationVariables = Exact<{
  credentials: gql_Credentials;
}>;


export type gql_LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResult', user: { __typename?: 'Admin', id: any, email: string, firstName?: string, lastName?: string, createdAt: any, updatedAt: any } } };

export type gql_LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type gql_LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type gql_GetAdminsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<gql_AdminFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  revert?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<gql_AdminSortByKeys>;
}>;


export type gql_GetAdminsQuery = { __typename?: 'Query', total: number, result: { __typename?: 'AdminConnection', pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, startCursor?: string, endCursor?: string }, nodes: Array<{ __typename?: 'Admin', id: any, email: string, firstName?: string, lastName?: string, createdAt: any, updatedAt: any }>, edges: Array<{ __typename?: 'AdminEdge', cursor: string, node: { __typename?: 'Admin', id: any, email: string, firstName?: string, lastName?: string, createdAt: any, updatedAt: any } }> } };

export type gql_GetAdminQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_GetAdminQuery = { __typename?: 'Query', admin?: { __typename?: 'Admin', id: any, email: string, firstName?: string, lastName?: string, createdAt: any, updatedAt: any } };

export type gql_CreateAdminMutationVariables = Exact<{
  data: gql_CreateAdminData;
}>;


export type gql_CreateAdminMutation = { __typename?: 'Mutation', createAdmin: { __typename?: 'Admin', id: any, email: string, firstName?: string, lastName?: string, createdAt: any, updatedAt: any } };

export type gql_UpdateAdminMutationVariables = Exact<{
  id: Scalars['ID'];
  data: gql_UpdateAdminData;
}>;


export type gql_UpdateAdminMutation = { __typename?: 'Mutation', updateAdmin: { __typename?: 'Admin', id: any, email: string, firstName?: string, lastName?: string, createdAt: any, updatedAt: any } };

export type gql_DeleteAdminMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_DeleteAdminMutation = { __typename?: 'Mutation', deleteAdmin: boolean };

export type gql_GetAppQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_GetAppQuery = { __typename?: 'Query', app?: { __typename?: 'App', id: any, name: string, active: boolean, hasApiKey: boolean, apiKeyCreatedAt?: any, hasVapidKeys: boolean, vapidPublicKey?: string, vapidSignatureMailto?: string, vapidKeysCreatedAt?: any, enabledOrigin?: string, createdAt: any, updatedAt: any } };

export type gql_GetAppsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<gql_AppFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  revert?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<gql_AppSortByKeys>;
}>;


export type gql_GetAppsQuery = { __typename?: 'Query', total: number, result: { __typename?: 'AppConnection', pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, startCursor?: string, endCursor?: string }, nodes: Array<{ __typename?: 'App', id: any, name: string, active: boolean, hasApiKey: boolean, apiKeyCreatedAt?: any, hasVapidKeys: boolean, vapidPublicKey?: string, vapidSignatureMailto?: string, vapidKeysCreatedAt?: any, enabledOrigin?: string, createdAt: any, updatedAt: any }>, edges: Array<{ __typename?: 'AppEdge', cursor: string, node: { __typename?: 'App', id: any, name: string, active: boolean, hasApiKey: boolean, apiKeyCreatedAt?: any, hasVapidKeys: boolean, vapidPublicKey?: string, vapidSignatureMailto?: string, vapidKeysCreatedAt?: any, enabledOrigin?: string, createdAt: any, updatedAt: any } }> } };

export type gql_CreateAppMutationVariables = Exact<{
  data: gql_CreateAppData;
}>;


export type gql_CreateAppMutation = { __typename?: 'Mutation', createApp: { __typename?: 'App', id: any, name: string, active: boolean, hasApiKey: boolean, apiKeyCreatedAt?: any, hasVapidKeys: boolean, vapidPublicKey?: string, vapidSignatureMailto?: string, vapidKeysCreatedAt?: any, enabledOrigin?: string, createdAt: any, updatedAt: any } };

export type gql_UpdateAppMutationVariables = Exact<{
  id: Scalars['ID'];
  data: gql_UpdateAppData;
}>;


export type gql_UpdateAppMutation = { __typename?: 'Mutation', updateApp: { __typename?: 'App', id: any, name: string, active: boolean, hasApiKey: boolean, apiKeyCreatedAt?: any, hasVapidKeys: boolean, vapidPublicKey?: string, vapidSignatureMailto?: string, vapidKeysCreatedAt?: any, enabledOrigin?: string, createdAt: any, updatedAt: any } };

export type gql_DeleteAppMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_DeleteAppMutation = { __typename?: 'Mutation', deleteApp: boolean };

export type gql_GenerateApiKeyMutationVariables = Exact<{
  appId: Scalars['ID'];
}>;


export type gql_GenerateApiKeyMutation = { __typename?: 'Mutation', generateApiKey: { __typename?: 'GeneratedApiKey', apiKey: string } };

export type gql_DestroyApiKeyMutationVariables = Exact<{
  appId: Scalars['ID'];
}>;


export type gql_DestroyApiKeyMutation = { __typename?: 'Mutation', destroyApiKey: boolean };

export type gql_GenerateVapidKeysMutationVariables = Exact<{
  appId: Scalars['ID'];
}>;


export type gql_GenerateVapidKeysMutation = { __typename?: 'Mutation', generateVapidKeys: { __typename?: 'GeneratedVapidKeys', publicKey: string } };

export type gql_DestroyVapidKeysMutationVariables = Exact<{
  appId: Scalars['ID'];
}>;


export type gql_DestroyVapidKeysMutation = { __typename?: 'Mutation', destroyVapidKeys: boolean };

export type gql_SetVapidSignatureMailToMutationVariables = Exact<{
  appId: Scalars['ID'];
  mailto: Scalars['String'];
}>;


export type gql_SetVapidSignatureMailToMutation = { __typename?: 'Mutation', setVapidSignatureMailto: string };

export type gql_ResetVapidSignatureMailToMutationVariables = Exact<{
  appId: Scalars['ID'];
}>;


export type gql_ResetVapidSignatureMailToMutation = { __typename?: 'Mutation', resetVapidSignatureMailto: boolean };

export type gql_SetEnabledOriginMutationVariables = Exact<{
  appId: Scalars['ID'];
  origin: Scalars['String'];
}>;


export type gql_SetEnabledOriginMutation = { __typename?: 'Mutation', setEnabledOrigin: string };

export type gql_ResetEnabledOriginMutationVariables = Exact<{
  appId: Scalars['ID'];
}>;


export type gql_ResetEnabledOriginMutation = { __typename?: 'Mutation', resetEnabledOrigin: boolean };

export type gql_GetAppChannelQueryVariables = Exact<{
  appId: Scalars['ID'];
  channel: gql_Channel;
}>;


export type gql_GetAppChannelQuery = { __typename?: 'Query', appChannel?: { __typename?: 'AppChannel', id: any, appId: any, channel: gql_Channel, value: any } };

export type gql_SetAppChannelMutationVariables = Exact<{
  appId: Scalars['ID'];
  channel: gql_Channel;
  value: Scalars['JSON'];
}>;


export type gql_SetAppChannelMutation = { __typename?: 'Mutation', setAppChannel: { __typename?: 'AppChannel', id: any, appId: any, channel: gql_Channel, value: any } };

export type gql_GetUsersQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<gql_UserFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  revert?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<gql_UserSortByKeys>;
}>;


export type gql_GetUsersQuery = { __typename?: 'Query', total: number, result: { __typename?: 'UserConnection', pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, startCursor?: string, endCursor?: string }, nodes: Array<{ __typename?: 'User', id: any, appId: any, refId: string, createdAt: any, updatedAt: any, channels: Array<{ __typename?: 'UserChannel', id: any, userId: any, channel: gql_Channel, details: any, active: boolean, createdAt: any }>, app?: { __typename?: 'App', id: any, name: string } }>, edges: Array<{ __typename?: 'UserEdge', cursor: string, node: { __typename?: 'User', id: any, appId: any, refId: string, createdAt: any, updatedAt: any, channels: Array<{ __typename?: 'UserChannel', id: any, userId: any, channel: gql_Channel, details: any, active: boolean, createdAt: any }>, app?: { __typename?: 'App', id: any, name: string } } }> } };

export type gql_GetUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_GetUserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: any, appId: any, refId: string, createdAt: any, updatedAt: any, channels: Array<{ __typename?: 'UserChannel', id: any, userId: any, channel: gql_Channel, details: any, active: boolean, createdAt: any }>, app?: { __typename?: 'App', id: any, name: string } } };

export type gql_CreateUserMutationVariables = Exact<{
  data: gql_CreateUserData;
}>;


export type gql_CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: any, appId: any, refId: string, createdAt: any, updatedAt: any, channels: Array<{ __typename?: 'UserChannel', id: any, userId: any, channel: gql_Channel, details: any, active: boolean, createdAt: any }>, app?: { __typename?: 'App', id: any, name: string } } };

export type gql_UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  data: gql_UpdateUserData;
}>;


export type gql_UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: any, appId: any, refId: string, createdAt: any, updatedAt: any, channels: Array<{ __typename?: 'UserChannel', id: any, userId: any, channel: gql_Channel, details: any, active: boolean, createdAt: any }>, app?: { __typename?: 'App', id: any, name: string } } };

export type gql_DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type gql_GetNotificationTypesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<gql_NotificationTypeFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  revert?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<gql_NotificationTypeSortByKeys>;
}>;


export type gql_GetNotificationTypesQuery = { __typename?: 'Query', total: number, result: { __typename?: 'NotificationTypeConnection', pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, startCursor?: string, endCursor?: string }, nodes: Array<{ __typename?: 'NotificationType', id: any, appId: any, name: string, slug: string, description: string, notificationCenterTitle?: string, notificationCenterBody?: string, notificationCenterIcon?: string, notificationCenterClickUrl?: string, emailTemplateSubject?: string, emailTemplateText?: string, emailTemplateHtmlPath?: string, tempEmailTemplateHtml?: string, smsTemplate?: string, webPushTemplate?: any, notificationCenterEnabled: boolean, emailEnabled: boolean, smsEnabled: boolean, webPushEnabled: boolean, active: boolean, createdAt: any, updatedAt: any, app?: { __typename?: 'App', id: any, name: string } }>, edges: Array<{ __typename?: 'NotificationTypeEdge', cursor: string, node: { __typename?: 'NotificationType', id: any, appId: any, name: string, slug: string, description: string, notificationCenterTitle?: string, notificationCenterBody?: string, notificationCenterIcon?: string, notificationCenterClickUrl?: string, emailTemplateSubject?: string, emailTemplateText?: string, emailTemplateHtmlPath?: string, tempEmailTemplateHtml?: string, smsTemplate?: string, webPushTemplate?: any, notificationCenterEnabled: boolean, emailEnabled: boolean, smsEnabled: boolean, webPushEnabled: boolean, active: boolean, createdAt: any, updatedAt: any, app?: { __typename?: 'App', id: any, name: string } } }> } };

export type gql_GetNotificationTypeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_GetNotificationTypeQuery = { __typename?: 'Query', notificationType?: { __typename?: 'NotificationType', id: any, appId: any, name: string, slug: string, description: string, notificationCenterTitle?: string, notificationCenterBody?: string, notificationCenterIcon?: string, notificationCenterClickUrl?: string, emailTemplateSubject?: string, emailTemplateText?: string, emailTemplateHtmlPath?: string, tempEmailTemplateHtml?: string, smsTemplate?: string, webPushTemplate?: any, notificationCenterEnabled: boolean, emailEnabled: boolean, smsEnabled: boolean, webPushEnabled: boolean, active: boolean, createdAt: any, updatedAt: any, app?: { __typename?: 'App', id: any, name: string } } };

export type gql_CreateNotificationTypeMutationVariables = Exact<{
  data: gql_CreateNotificationTypeData;
}>;


export type gql_CreateNotificationTypeMutation = { __typename?: 'Mutation', createNotificationType: { __typename?: 'NotificationType', id: any, appId: any, name: string, slug: string, description: string, notificationCenterTitle?: string, notificationCenterBody?: string, notificationCenterIcon?: string, notificationCenterClickUrl?: string, emailTemplateSubject?: string, emailTemplateText?: string, emailTemplateHtmlPath?: string, tempEmailTemplateHtml?: string, smsTemplate?: string, webPushTemplate?: any, notificationCenterEnabled: boolean, emailEnabled: boolean, smsEnabled: boolean, webPushEnabled: boolean, active: boolean, createdAt: any, updatedAt: any, app?: { __typename?: 'App', id: any, name: string } } };

export type gql_UpdateNotificationTypeMutationVariables = Exact<{
  id: Scalars['ID'];
  data: gql_UpdateNotificationTypeData;
}>;


export type gql_UpdateNotificationTypeMutation = { __typename?: 'Mutation', updateNotificationType: { __typename?: 'NotificationType', id: any, appId: any, name: string, slug: string, description: string, notificationCenterTitle?: string, notificationCenterBody?: string, notificationCenterIcon?: string, notificationCenterClickUrl?: string, emailTemplateSubject?: string, emailTemplateText?: string, emailTemplateHtmlPath?: string, tempEmailTemplateHtml?: string, smsTemplate?: string, webPushTemplate?: any, notificationCenterEnabled: boolean, emailEnabled: boolean, smsEnabled: boolean, webPushEnabled: boolean, active: boolean, createdAt: any, updatedAt: any, app?: { __typename?: 'App', id: any, name: string } } };

export type gql_DeleteNotificationTypeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_DeleteNotificationTypeMutation = { __typename?: 'Mutation', deleteNotificationType: boolean };

export type gql_GetNotificationsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<gql_NotificationFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  revert?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<gql_NotificationSortByKeys>;
}>;


export type gql_GetNotificationsQuery = { __typename?: 'Query', total: number, result: { __typename?: 'NotificationConnection', pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, startCursor?: string, endCursor?: string }, nodes: Array<{ __typename?: 'Notification', id: any, appId: any, senderId?: any, typeId?: any, rawContent?: any, replacements?: any, addedToQueueAt?: any, rawChannels?: any, scheduledFor?: any, createdAt: any, updatedAt: any, notificationType?: { __typename?: 'NotificationType', id: any, name: string, notificationCenterTitle?: string, emailTemplateSubject?: string, emailTemplateText?: string, emailTemplateHtmlPath?: string, tempEmailTemplateHtml?: string, smsTemplate?: string, webPushTemplate?: any, notificationCenterEnabled: boolean, emailEnabled: boolean, smsEnabled: boolean, webPushEnabled: boolean } }>, edges: Array<{ __typename?: 'NotificationEdge', cursor: string, node: { __typename?: 'Notification', id: any, appId: any, senderId?: any, typeId?: any, rawContent?: any, replacements?: any, addedToQueueAt?: any, rawChannels?: any, scheduledFor?: any, createdAt: any, updatedAt: any, notificationType?: { __typename?: 'NotificationType', id: any, name: string, notificationCenterTitle?: string, emailTemplateSubject?: string, emailTemplateText?: string, emailTemplateHtmlPath?: string, tempEmailTemplateHtml?: string, smsTemplate?: string, webPushTemplate?: any, notificationCenterEnabled: boolean, emailEnabled: boolean, smsEnabled: boolean, webPushEnabled: boolean } } }> } };

export type gql_GetNotificationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_GetNotificationQuery = { __typename?: 'Query', notification?: { __typename?: 'Notification', id: any, appId: any, senderId?: any, typeId?: any, rawContent?: any, replacements?: any, addedToQueueAt?: any, rawChannels?: any, scheduledFor?: any, createdAt: any, updatedAt: any, notificationType?: { __typename?: 'NotificationType', id: any, name: string, notificationCenterTitle?: string, emailTemplateSubject?: string, emailTemplateText?: string, emailTemplateHtmlPath?: string, tempEmailTemplateHtml?: string, smsTemplate?: string, webPushTemplate?: any, notificationCenterEnabled: boolean, emailEnabled: boolean, smsEnabled: boolean, webPushEnabled: boolean } } };

export type gql_CreateNotificationMutationVariables = Exact<{
  data: gql_CreateNotificationData;
}>;


export type gql_CreateNotificationMutation = { __typename?: 'Mutation', createNotification: { __typename?: 'Notification', id: any, appId: any, senderId?: any, typeId?: any, rawContent?: any, replacements?: any, addedToQueueAt?: any, rawChannels?: any, scheduledFor?: any, createdAt: any, updatedAt: any, notificationType?: { __typename?: 'NotificationType', id: any, name: string, notificationCenterTitle?: string, emailTemplateSubject?: string, emailTemplateText?: string, emailTemplateHtmlPath?: string, tempEmailTemplateHtml?: string, smsTemplate?: string, webPushTemplate?: any, notificationCenterEnabled: boolean, emailEnabled: boolean, smsEnabled: boolean, webPushEnabled: boolean } } };

export type gql_DeleteNotificationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type gql_DeleteNotificationMutation = { __typename?: 'Mutation', deleteNotification: boolean };

export const AdminFragmentFragmentDoc = gql`
    fragment AdminFragment on Admin {
  id
  email
  firstName
  lastName
  createdAt
  updatedAt
}
    `;
export const AppFragmentFragmentDoc = gql`
    fragment AppFragment on App {
  id
  name
  active
  hasApiKey
  apiKeyCreatedAt
  hasVapidKeys
  vapidPublicKey
  vapidSignatureMailto
  vapidKeysCreatedAt
  enabledOrigin
  createdAt
  updatedAt
}
    `;
export const AppChannelFragmentFragmentDoc = gql`
    fragment AppChannelFragment on AppChannel {
  id
  appId
  channel
  value
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  appId
  refId
  channels {
    id
    userId
    channel
    details
    active
    createdAt
  }
  app {
    id
    name
  }
  createdAt
  updatedAt
}
    `;
export const NotificationTypeFragmentFragmentDoc = gql`
    fragment NotificationTypeFragment on NotificationType {
  id
  appId
  app {
    id
    name
  }
  name
  slug
  description
  notificationCenterTitle
  notificationCenterBody
  notificationCenterIcon
  notificationCenterClickUrl
  notificationCenterIcon
  emailTemplateSubject
  emailTemplateText
  emailTemplateHtmlPath
  tempEmailTemplateHtml
  smsTemplate
  webPushTemplate
  notificationCenterEnabled
  emailEnabled
  smsEnabled
  webPushEnabled
  active
  createdAt
  updatedAt
}
    `;
export const NotificationFragmentFragmentDoc = gql`
    fragment NotificationFragment on Notification {
  id
  appId
  senderId
  typeId
  rawContent
  replacements
  addedToQueueAt
  rawChannels
  notificationType {
    id
    name
    notificationCenterTitle
    emailTemplateSubject
    emailTemplateText
    emailTemplateHtmlPath
    tempEmailTemplateHtml
    smsTemplate
    webPushTemplate
    notificationCenterEnabled
    emailEnabled
    smsEnabled
    webPushEnabled
  }
  scheduledFor
  createdAt
  updatedAt
}
    `;
export const GetAdminMeDocument = gql`
    query GetAdminMe {
  adminMe {
    ...AdminFragment
  }
}
    ${AdminFragmentFragmentDoc}`;

/**
 * __useGetAdminMeQuery__
 *
 * To run a query within a React component, call `useGetAdminMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminMeQuery(baseOptions?: Apollo.QueryHookOptions<gql_GetAdminMeQuery, gql_GetAdminMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetAdminMeQuery, gql_GetAdminMeQueryVariables>(GetAdminMeDocument, options);
      }
export function useGetAdminMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetAdminMeQuery, gql_GetAdminMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetAdminMeQuery, gql_GetAdminMeQueryVariables>(GetAdminMeDocument, options);
        }
export type GetAdminMeQueryHookResult = ReturnType<typeof useGetAdminMeQuery>;
export type GetAdminMeLazyQueryHookResult = ReturnType<typeof useGetAdminMeLazyQuery>;
export type GetAdminMeQueryResult = Apollo.QueryResult<gql_GetAdminMeQuery, gql_GetAdminMeQueryVariables>;
export const LoginDocument = gql`
    mutation Login($credentials: Credentials!) {
  login(credentials: $credentials) {
    user {
      ...AdminFragment
    }
  }
}
    ${AdminFragmentFragmentDoc}`;
export type gql_LoginMutationFn = Apollo.MutationFunction<gql_LoginMutation, gql_LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      credentials: // value for 'credentials'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<gql_LoginMutation, gql_LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_LoginMutation, gql_LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<gql_LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<gql_LoginMutation, gql_LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type gql_LogoutMutationFn = Apollo.MutationFunction<gql_LogoutMutation, gql_LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<gql_LogoutMutation, gql_LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_LogoutMutation, gql_LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<gql_LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<gql_LogoutMutation, gql_LogoutMutationVariables>;
export const GetAdminsDocument = gql`
    query GetAdmins($after: String, $before: String, $filters: AdminFilters, $first: Int, $last: Int, $revert: Boolean, $sortBy: AdminSortByKeys) {
  total: adminsCount
  result: admins(
    after: $after
    before: $before
    filters: $filters
    first: $first
    last: $last
    revert: $revert
    sortBy: $sortBy
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    nodes {
      ...AdminFragment
    }
    edges {
      cursor
      node {
        ...AdminFragment
      }
    }
  }
}
    ${AdminFragmentFragmentDoc}`;

/**
 * __useGetAdminsQuery__
 *
 * To run a query within a React component, call `useGetAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      revert: // value for 'revert'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetAdminsQuery(baseOptions?: Apollo.QueryHookOptions<gql_GetAdminsQuery, gql_GetAdminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetAdminsQuery, gql_GetAdminsQueryVariables>(GetAdminsDocument, options);
      }
export function useGetAdminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetAdminsQuery, gql_GetAdminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetAdminsQuery, gql_GetAdminsQueryVariables>(GetAdminsDocument, options);
        }
export type GetAdminsQueryHookResult = ReturnType<typeof useGetAdminsQuery>;
export type GetAdminsLazyQueryHookResult = ReturnType<typeof useGetAdminsLazyQuery>;
export type GetAdminsQueryResult = Apollo.QueryResult<gql_GetAdminsQuery, gql_GetAdminsQueryVariables>;
export const GetAdminDocument = gql`
    query GetAdmin($id: ID!) {
  admin(id: $id) {
    ...AdminFragment
  }
}
    ${AdminFragmentFragmentDoc}`;

/**
 * __useGetAdminQuery__
 *
 * To run a query within a React component, call `useGetAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminQuery(baseOptions: Apollo.QueryHookOptions<gql_GetAdminQuery, gql_GetAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetAdminQuery, gql_GetAdminQueryVariables>(GetAdminDocument, options);
      }
export function useGetAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetAdminQuery, gql_GetAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetAdminQuery, gql_GetAdminQueryVariables>(GetAdminDocument, options);
        }
export type GetAdminQueryHookResult = ReturnType<typeof useGetAdminQuery>;
export type GetAdminLazyQueryHookResult = ReturnType<typeof useGetAdminLazyQuery>;
export type GetAdminQueryResult = Apollo.QueryResult<gql_GetAdminQuery, gql_GetAdminQueryVariables>;
export const CreateAdminDocument = gql`
    mutation CreateAdmin($data: CreateAdminData!) {
  createAdmin(data: $data) {
    ...AdminFragment
  }
}
    ${AdminFragmentFragmentDoc}`;
export type gql_CreateAdminMutationFn = Apollo.MutationFunction<gql_CreateAdminMutation, gql_CreateAdminMutationVariables>;

/**
 * __useCreateAdminMutation__
 *
 * To run a mutation, you first call `useCreateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminMutation, { data, loading, error }] = useCreateAdminMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAdminMutation(baseOptions?: Apollo.MutationHookOptions<gql_CreateAdminMutation, gql_CreateAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_CreateAdminMutation, gql_CreateAdminMutationVariables>(CreateAdminDocument, options);
      }
export type CreateAdminMutationHookResult = ReturnType<typeof useCreateAdminMutation>;
export type CreateAdminMutationResult = Apollo.MutationResult<gql_CreateAdminMutation>;
export type CreateAdminMutationOptions = Apollo.BaseMutationOptions<gql_CreateAdminMutation, gql_CreateAdminMutationVariables>;
export const UpdateAdminDocument = gql`
    mutation UpdateAdmin($id: ID!, $data: UpdateAdminData!) {
  updateAdmin(id: $id, data: $data) {
    ...AdminFragment
  }
}
    ${AdminFragmentFragmentDoc}`;
export type gql_UpdateAdminMutationFn = Apollo.MutationFunction<gql_UpdateAdminMutation, gql_UpdateAdminMutationVariables>;

/**
 * __useUpdateAdminMutation__
 *
 * To run a mutation, you first call `useUpdateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminMutation, { data, loading, error }] = useUpdateAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAdminMutation(baseOptions?: Apollo.MutationHookOptions<gql_UpdateAdminMutation, gql_UpdateAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_UpdateAdminMutation, gql_UpdateAdminMutationVariables>(UpdateAdminDocument, options);
      }
export type UpdateAdminMutationHookResult = ReturnType<typeof useUpdateAdminMutation>;
export type UpdateAdminMutationResult = Apollo.MutationResult<gql_UpdateAdminMutation>;
export type UpdateAdminMutationOptions = Apollo.BaseMutationOptions<gql_UpdateAdminMutation, gql_UpdateAdminMutationVariables>;
export const DeleteAdminDocument = gql`
    mutation DeleteAdmin($id: ID!) {
  deleteAdmin(id: $id)
}
    `;
export type gql_DeleteAdminMutationFn = Apollo.MutationFunction<gql_DeleteAdminMutation, gql_DeleteAdminMutationVariables>;

/**
 * __useDeleteAdminMutation__
 *
 * To run a mutation, you first call `useDeleteAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminMutation, { data, loading, error }] = useDeleteAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAdminMutation(baseOptions?: Apollo.MutationHookOptions<gql_DeleteAdminMutation, gql_DeleteAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_DeleteAdminMutation, gql_DeleteAdminMutationVariables>(DeleteAdminDocument, options);
      }
export type DeleteAdminMutationHookResult = ReturnType<typeof useDeleteAdminMutation>;
export type DeleteAdminMutationResult = Apollo.MutationResult<gql_DeleteAdminMutation>;
export type DeleteAdminMutationOptions = Apollo.BaseMutationOptions<gql_DeleteAdminMutation, gql_DeleteAdminMutationVariables>;
export const GetAppDocument = gql`
    query GetApp($id: ID!) {
  app(id: $id) {
    ...AppFragment
  }
}
    ${AppFragmentFragmentDoc}`;

/**
 * __useGetAppQuery__
 *
 * To run a query within a React component, call `useGetAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAppQuery(baseOptions: Apollo.QueryHookOptions<gql_GetAppQuery, gql_GetAppQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetAppQuery, gql_GetAppQueryVariables>(GetAppDocument, options);
      }
export function useGetAppLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetAppQuery, gql_GetAppQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetAppQuery, gql_GetAppQueryVariables>(GetAppDocument, options);
        }
export type GetAppQueryHookResult = ReturnType<typeof useGetAppQuery>;
export type GetAppLazyQueryHookResult = ReturnType<typeof useGetAppLazyQuery>;
export type GetAppQueryResult = Apollo.QueryResult<gql_GetAppQuery, gql_GetAppQueryVariables>;
export const GetAppsDocument = gql`
    query GetApps($after: String, $before: String, $filters: AppFilters, $first: Int, $last: Int, $revert: Boolean, $sortBy: AppSortByKeys) {
  total: appsCount
  result: apps(
    after: $after
    before: $before
    filters: $filters
    first: $first
    last: $last
    revert: $revert
    sortBy: $sortBy
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    nodes {
      ...AppFragment
    }
    edges {
      cursor
      node {
        ...AppFragment
      }
    }
  }
}
    ${AppFragmentFragmentDoc}`;

/**
 * __useGetAppsQuery__
 *
 * To run a query within a React component, call `useGetAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      revert: // value for 'revert'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetAppsQuery(baseOptions?: Apollo.QueryHookOptions<gql_GetAppsQuery, gql_GetAppsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetAppsQuery, gql_GetAppsQueryVariables>(GetAppsDocument, options);
      }
export function useGetAppsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetAppsQuery, gql_GetAppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetAppsQuery, gql_GetAppsQueryVariables>(GetAppsDocument, options);
        }
export type GetAppsQueryHookResult = ReturnType<typeof useGetAppsQuery>;
export type GetAppsLazyQueryHookResult = ReturnType<typeof useGetAppsLazyQuery>;
export type GetAppsQueryResult = Apollo.QueryResult<gql_GetAppsQuery, gql_GetAppsQueryVariables>;
export const CreateAppDocument = gql`
    mutation CreateApp($data: CreateAppData!) {
  createApp(data: $data) {
    ...AppFragment
  }
}
    ${AppFragmentFragmentDoc}`;
export type gql_CreateAppMutationFn = Apollo.MutationFunction<gql_CreateAppMutation, gql_CreateAppMutationVariables>;

/**
 * __useCreateAppMutation__
 *
 * To run a mutation, you first call `useCreateAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppMutation, { data, loading, error }] = useCreateAppMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAppMutation(baseOptions?: Apollo.MutationHookOptions<gql_CreateAppMutation, gql_CreateAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_CreateAppMutation, gql_CreateAppMutationVariables>(CreateAppDocument, options);
      }
export type CreateAppMutationHookResult = ReturnType<typeof useCreateAppMutation>;
export type CreateAppMutationResult = Apollo.MutationResult<gql_CreateAppMutation>;
export type CreateAppMutationOptions = Apollo.BaseMutationOptions<gql_CreateAppMutation, gql_CreateAppMutationVariables>;
export const UpdateAppDocument = gql`
    mutation UpdateApp($id: ID!, $data: UpdateAppData!) {
  updateApp(id: $id, data: $data) {
    ...AppFragment
  }
}
    ${AppFragmentFragmentDoc}`;
export type gql_UpdateAppMutationFn = Apollo.MutationFunction<gql_UpdateAppMutation, gql_UpdateAppMutationVariables>;

/**
 * __useUpdateAppMutation__
 *
 * To run a mutation, you first call `useUpdateAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppMutation, { data, loading, error }] = useUpdateAppMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAppMutation(baseOptions?: Apollo.MutationHookOptions<gql_UpdateAppMutation, gql_UpdateAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_UpdateAppMutation, gql_UpdateAppMutationVariables>(UpdateAppDocument, options);
      }
export type UpdateAppMutationHookResult = ReturnType<typeof useUpdateAppMutation>;
export type UpdateAppMutationResult = Apollo.MutationResult<gql_UpdateAppMutation>;
export type UpdateAppMutationOptions = Apollo.BaseMutationOptions<gql_UpdateAppMutation, gql_UpdateAppMutationVariables>;
export const DeleteAppDocument = gql`
    mutation DeleteApp($id: ID!) {
  deleteApp(id: $id)
}
    `;
export type gql_DeleteAppMutationFn = Apollo.MutationFunction<gql_DeleteAppMutation, gql_DeleteAppMutationVariables>;

/**
 * __useDeleteAppMutation__
 *
 * To run a mutation, you first call `useDeleteAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppMutation, { data, loading, error }] = useDeleteAppMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAppMutation(baseOptions?: Apollo.MutationHookOptions<gql_DeleteAppMutation, gql_DeleteAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_DeleteAppMutation, gql_DeleteAppMutationVariables>(DeleteAppDocument, options);
      }
export type DeleteAppMutationHookResult = ReturnType<typeof useDeleteAppMutation>;
export type DeleteAppMutationResult = Apollo.MutationResult<gql_DeleteAppMutation>;
export type DeleteAppMutationOptions = Apollo.BaseMutationOptions<gql_DeleteAppMutation, gql_DeleteAppMutationVariables>;
export const GenerateApiKeyDocument = gql`
    mutation GenerateApiKey($appId: ID!) {
  generateApiKey(appId: $appId) {
    apiKey
  }
}
    `;
export type gql_GenerateApiKeyMutationFn = Apollo.MutationFunction<gql_GenerateApiKeyMutation, gql_GenerateApiKeyMutationVariables>;

/**
 * __useGenerateApiKeyMutation__
 *
 * To run a mutation, you first call `useGenerateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateApiKeyMutation, { data, loading, error }] = useGenerateApiKeyMutation({
 *   variables: {
 *      appId: // value for 'appId'
 *   },
 * });
 */
export function useGenerateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<gql_GenerateApiKeyMutation, gql_GenerateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_GenerateApiKeyMutation, gql_GenerateApiKeyMutationVariables>(GenerateApiKeyDocument, options);
      }
export type GenerateApiKeyMutationHookResult = ReturnType<typeof useGenerateApiKeyMutation>;
export type GenerateApiKeyMutationResult = Apollo.MutationResult<gql_GenerateApiKeyMutation>;
export type GenerateApiKeyMutationOptions = Apollo.BaseMutationOptions<gql_GenerateApiKeyMutation, gql_GenerateApiKeyMutationVariables>;
export const DestroyApiKeyDocument = gql`
    mutation DestroyApiKey($appId: ID!) {
  destroyApiKey(appId: $appId)
}
    `;
export type gql_DestroyApiKeyMutationFn = Apollo.MutationFunction<gql_DestroyApiKeyMutation, gql_DestroyApiKeyMutationVariables>;

/**
 * __useDestroyApiKeyMutation__
 *
 * To run a mutation, you first call `useDestroyApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyApiKeyMutation, { data, loading, error }] = useDestroyApiKeyMutation({
 *   variables: {
 *      appId: // value for 'appId'
 *   },
 * });
 */
export function useDestroyApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<gql_DestroyApiKeyMutation, gql_DestroyApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_DestroyApiKeyMutation, gql_DestroyApiKeyMutationVariables>(DestroyApiKeyDocument, options);
      }
export type DestroyApiKeyMutationHookResult = ReturnType<typeof useDestroyApiKeyMutation>;
export type DestroyApiKeyMutationResult = Apollo.MutationResult<gql_DestroyApiKeyMutation>;
export type DestroyApiKeyMutationOptions = Apollo.BaseMutationOptions<gql_DestroyApiKeyMutation, gql_DestroyApiKeyMutationVariables>;
export const GenerateVapidKeysDocument = gql`
    mutation GenerateVapidKeys($appId: ID!) {
  generateVapidKeys(appId: $appId) {
    publicKey
  }
}
    `;
export type gql_GenerateVapidKeysMutationFn = Apollo.MutationFunction<gql_GenerateVapidKeysMutation, gql_GenerateVapidKeysMutationVariables>;

/**
 * __useGenerateVapidKeysMutation__
 *
 * To run a mutation, you first call `useGenerateVapidKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateVapidKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateVapidKeysMutation, { data, loading, error }] = useGenerateVapidKeysMutation({
 *   variables: {
 *      appId: // value for 'appId'
 *   },
 * });
 */
export function useGenerateVapidKeysMutation(baseOptions?: Apollo.MutationHookOptions<gql_GenerateVapidKeysMutation, gql_GenerateVapidKeysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_GenerateVapidKeysMutation, gql_GenerateVapidKeysMutationVariables>(GenerateVapidKeysDocument, options);
      }
export type GenerateVapidKeysMutationHookResult = ReturnType<typeof useGenerateVapidKeysMutation>;
export type GenerateVapidKeysMutationResult = Apollo.MutationResult<gql_GenerateVapidKeysMutation>;
export type GenerateVapidKeysMutationOptions = Apollo.BaseMutationOptions<gql_GenerateVapidKeysMutation, gql_GenerateVapidKeysMutationVariables>;
export const DestroyVapidKeysDocument = gql`
    mutation DestroyVapidKeys($appId: ID!) {
  destroyVapidKeys(appId: $appId)
}
    `;
export type gql_DestroyVapidKeysMutationFn = Apollo.MutationFunction<gql_DestroyVapidKeysMutation, gql_DestroyVapidKeysMutationVariables>;

/**
 * __useDestroyVapidKeysMutation__
 *
 * To run a mutation, you first call `useDestroyVapidKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyVapidKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyVapidKeysMutation, { data, loading, error }] = useDestroyVapidKeysMutation({
 *   variables: {
 *      appId: // value for 'appId'
 *   },
 * });
 */
export function useDestroyVapidKeysMutation(baseOptions?: Apollo.MutationHookOptions<gql_DestroyVapidKeysMutation, gql_DestroyVapidKeysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_DestroyVapidKeysMutation, gql_DestroyVapidKeysMutationVariables>(DestroyVapidKeysDocument, options);
      }
export type DestroyVapidKeysMutationHookResult = ReturnType<typeof useDestroyVapidKeysMutation>;
export type DestroyVapidKeysMutationResult = Apollo.MutationResult<gql_DestroyVapidKeysMutation>;
export type DestroyVapidKeysMutationOptions = Apollo.BaseMutationOptions<gql_DestroyVapidKeysMutation, gql_DestroyVapidKeysMutationVariables>;
export const SetVapidSignatureMailToDocument = gql`
    mutation SetVapidSignatureMailTo($appId: ID!, $mailto: String!) {
  setVapidSignatureMailto(appId: $appId, mailto: $mailto)
}
    `;
export type gql_SetVapidSignatureMailToMutationFn = Apollo.MutationFunction<gql_SetVapidSignatureMailToMutation, gql_SetVapidSignatureMailToMutationVariables>;

/**
 * __useSetVapidSignatureMailToMutation__
 *
 * To run a mutation, you first call `useSetVapidSignatureMailToMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVapidSignatureMailToMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVapidSignatureMailToMutation, { data, loading, error }] = useSetVapidSignatureMailToMutation({
 *   variables: {
 *      appId: // value for 'appId'
 *      mailto: // value for 'mailto'
 *   },
 * });
 */
export function useSetVapidSignatureMailToMutation(baseOptions?: Apollo.MutationHookOptions<gql_SetVapidSignatureMailToMutation, gql_SetVapidSignatureMailToMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_SetVapidSignatureMailToMutation, gql_SetVapidSignatureMailToMutationVariables>(SetVapidSignatureMailToDocument, options);
      }
export type SetVapidSignatureMailToMutationHookResult = ReturnType<typeof useSetVapidSignatureMailToMutation>;
export type SetVapidSignatureMailToMutationResult = Apollo.MutationResult<gql_SetVapidSignatureMailToMutation>;
export type SetVapidSignatureMailToMutationOptions = Apollo.BaseMutationOptions<gql_SetVapidSignatureMailToMutation, gql_SetVapidSignatureMailToMutationVariables>;
export const ResetVapidSignatureMailToDocument = gql`
    mutation ResetVapidSignatureMailTo($appId: ID!) {
  resetVapidSignatureMailto(appId: $appId)
}
    `;
export type gql_ResetVapidSignatureMailToMutationFn = Apollo.MutationFunction<gql_ResetVapidSignatureMailToMutation, gql_ResetVapidSignatureMailToMutationVariables>;

/**
 * __useResetVapidSignatureMailToMutation__
 *
 * To run a mutation, you first call `useResetVapidSignatureMailToMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetVapidSignatureMailToMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetVapidSignatureMailToMutation, { data, loading, error }] = useResetVapidSignatureMailToMutation({
 *   variables: {
 *      appId: // value for 'appId'
 *   },
 * });
 */
export function useResetVapidSignatureMailToMutation(baseOptions?: Apollo.MutationHookOptions<gql_ResetVapidSignatureMailToMutation, gql_ResetVapidSignatureMailToMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_ResetVapidSignatureMailToMutation, gql_ResetVapidSignatureMailToMutationVariables>(ResetVapidSignatureMailToDocument, options);
      }
export type ResetVapidSignatureMailToMutationHookResult = ReturnType<typeof useResetVapidSignatureMailToMutation>;
export type ResetVapidSignatureMailToMutationResult = Apollo.MutationResult<gql_ResetVapidSignatureMailToMutation>;
export type ResetVapidSignatureMailToMutationOptions = Apollo.BaseMutationOptions<gql_ResetVapidSignatureMailToMutation, gql_ResetVapidSignatureMailToMutationVariables>;
export const SetEnabledOriginDocument = gql`
    mutation SetEnabledOrigin($appId: ID!, $origin: String!) {
  setEnabledOrigin(appId: $appId, origin: $origin)
}
    `;
export type gql_SetEnabledOriginMutationFn = Apollo.MutationFunction<gql_SetEnabledOriginMutation, gql_SetEnabledOriginMutationVariables>;

/**
 * __useSetEnabledOriginMutation__
 *
 * To run a mutation, you first call `useSetEnabledOriginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEnabledOriginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEnabledOriginMutation, { data, loading, error }] = useSetEnabledOriginMutation({
 *   variables: {
 *      appId: // value for 'appId'
 *      origin: // value for 'origin'
 *   },
 * });
 */
export function useSetEnabledOriginMutation(baseOptions?: Apollo.MutationHookOptions<gql_SetEnabledOriginMutation, gql_SetEnabledOriginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_SetEnabledOriginMutation, gql_SetEnabledOriginMutationVariables>(SetEnabledOriginDocument, options);
      }
export type SetEnabledOriginMutationHookResult = ReturnType<typeof useSetEnabledOriginMutation>;
export type SetEnabledOriginMutationResult = Apollo.MutationResult<gql_SetEnabledOriginMutation>;
export type SetEnabledOriginMutationOptions = Apollo.BaseMutationOptions<gql_SetEnabledOriginMutation, gql_SetEnabledOriginMutationVariables>;
export const ResetEnabledOriginDocument = gql`
    mutation ResetEnabledOrigin($appId: ID!) {
  resetEnabledOrigin(appId: $appId)
}
    `;
export type gql_ResetEnabledOriginMutationFn = Apollo.MutationFunction<gql_ResetEnabledOriginMutation, gql_ResetEnabledOriginMutationVariables>;

/**
 * __useResetEnabledOriginMutation__
 *
 * To run a mutation, you first call `useResetEnabledOriginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetEnabledOriginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetEnabledOriginMutation, { data, loading, error }] = useResetEnabledOriginMutation({
 *   variables: {
 *      appId: // value for 'appId'
 *   },
 * });
 */
export function useResetEnabledOriginMutation(baseOptions?: Apollo.MutationHookOptions<gql_ResetEnabledOriginMutation, gql_ResetEnabledOriginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_ResetEnabledOriginMutation, gql_ResetEnabledOriginMutationVariables>(ResetEnabledOriginDocument, options);
      }
export type ResetEnabledOriginMutationHookResult = ReturnType<typeof useResetEnabledOriginMutation>;
export type ResetEnabledOriginMutationResult = Apollo.MutationResult<gql_ResetEnabledOriginMutation>;
export type ResetEnabledOriginMutationOptions = Apollo.BaseMutationOptions<gql_ResetEnabledOriginMutation, gql_ResetEnabledOriginMutationVariables>;
export const GetAppChannelDocument = gql`
    query GetAppChannel($appId: ID!, $channel: Channel!) {
  appChannel(appId: $appId, channel: $channel) {
    ...AppChannelFragment
  }
}
    ${AppChannelFragmentFragmentDoc}`;

/**
 * __useGetAppChannelQuery__
 *
 * To run a query within a React component, call `useGetAppChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppChannelQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      channel: // value for 'channel'
 *   },
 * });
 */
export function useGetAppChannelQuery(baseOptions: Apollo.QueryHookOptions<gql_GetAppChannelQuery, gql_GetAppChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetAppChannelQuery, gql_GetAppChannelQueryVariables>(GetAppChannelDocument, options);
      }
export function useGetAppChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetAppChannelQuery, gql_GetAppChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetAppChannelQuery, gql_GetAppChannelQueryVariables>(GetAppChannelDocument, options);
        }
export type GetAppChannelQueryHookResult = ReturnType<typeof useGetAppChannelQuery>;
export type GetAppChannelLazyQueryHookResult = ReturnType<typeof useGetAppChannelLazyQuery>;
export type GetAppChannelQueryResult = Apollo.QueryResult<gql_GetAppChannelQuery, gql_GetAppChannelQueryVariables>;
export const SetAppChannelDocument = gql`
    mutation SetAppChannel($appId: ID!, $channel: Channel!, $value: JSON!) {
  setAppChannel(appId: $appId, channel: $channel, value: $value) {
    ...AppChannelFragment
  }
}
    ${AppChannelFragmentFragmentDoc}`;
export type gql_SetAppChannelMutationFn = Apollo.MutationFunction<gql_SetAppChannelMutation, gql_SetAppChannelMutationVariables>;

/**
 * __useSetAppChannelMutation__
 *
 * To run a mutation, you first call `useSetAppChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAppChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAppChannelMutation, { data, loading, error }] = useSetAppChannelMutation({
 *   variables: {
 *      appId: // value for 'appId'
 *      channel: // value for 'channel'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetAppChannelMutation(baseOptions?: Apollo.MutationHookOptions<gql_SetAppChannelMutation, gql_SetAppChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_SetAppChannelMutation, gql_SetAppChannelMutationVariables>(SetAppChannelDocument, options);
      }
export type SetAppChannelMutationHookResult = ReturnType<typeof useSetAppChannelMutation>;
export type SetAppChannelMutationResult = Apollo.MutationResult<gql_SetAppChannelMutation>;
export type SetAppChannelMutationOptions = Apollo.BaseMutationOptions<gql_SetAppChannelMutation, gql_SetAppChannelMutationVariables>;
export const GetUsersDocument = gql`
    query GetUsers($after: String, $before: String, $filters: UserFilters, $first: Int, $last: Int, $revert: Boolean, $sortBy: UserSortByKeys) {
  total: usersCount
  result: users(
    after: $after
    before: $before
    filters: $filters
    first: $first
    last: $last
    revert: $revert
    sortBy: $sortBy
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    nodes {
      ...UserFragment
    }
    edges {
      cursor
      node {
        ...UserFragment
      }
    }
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      revert: // value for 'revert'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<gql_GetUsersQuery, gql_GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetUsersQuery, gql_GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetUsersQuery, gql_GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetUsersQuery, gql_GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<gql_GetUsersQuery, gql_GetUsersQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: ID!) {
  user(id: $id) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<gql_GetUserQuery, gql_GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetUserQuery, gql_GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetUserQuery, gql_GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetUserQuery, gql_GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<gql_GetUserQuery, gql_GetUserQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($data: CreateUserData!) {
  createUser(data: $data) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type gql_CreateUserMutationFn = Apollo.MutationFunction<gql_CreateUserMutation, gql_CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<gql_CreateUserMutation, gql_CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_CreateUserMutation, gql_CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<gql_CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<gql_CreateUserMutation, gql_CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $data: UpdateUserData!) {
  updateUser(id: $id, data: $data) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type gql_UpdateUserMutationFn = Apollo.MutationFunction<gql_UpdateUserMutation, gql_UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<gql_UpdateUserMutation, gql_UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_UpdateUserMutation, gql_UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<gql_UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<gql_UpdateUserMutation, gql_UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  deleteUser(id: $id)
}
    `;
export type gql_DeleteUserMutationFn = Apollo.MutationFunction<gql_DeleteUserMutation, gql_DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<gql_DeleteUserMutation, gql_DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_DeleteUserMutation, gql_DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<gql_DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<gql_DeleteUserMutation, gql_DeleteUserMutationVariables>;
export const GetNotificationTypesDocument = gql`
    query GetNotificationTypes($after: String, $before: String, $filters: NotificationTypeFilters, $first: Int, $last: Int, $revert: Boolean, $sortBy: NotificationTypeSortByKeys) {
  total: usersCount
  result: notificationTypes(
    after: $after
    before: $before
    filters: $filters
    first: $first
    last: $last
    revert: $revert
    sortBy: $sortBy
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    nodes {
      ...NotificationTypeFragment
    }
    edges {
      cursor
      node {
        ...NotificationTypeFragment
      }
    }
  }
}
    ${NotificationTypeFragmentFragmentDoc}`;

/**
 * __useGetNotificationTypesQuery__
 *
 * To run a query within a React component, call `useGetNotificationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationTypesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      revert: // value for 'revert'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetNotificationTypesQuery(baseOptions?: Apollo.QueryHookOptions<gql_GetNotificationTypesQuery, gql_GetNotificationTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetNotificationTypesQuery, gql_GetNotificationTypesQueryVariables>(GetNotificationTypesDocument, options);
      }
export function useGetNotificationTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetNotificationTypesQuery, gql_GetNotificationTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetNotificationTypesQuery, gql_GetNotificationTypesQueryVariables>(GetNotificationTypesDocument, options);
        }
export type GetNotificationTypesQueryHookResult = ReturnType<typeof useGetNotificationTypesQuery>;
export type GetNotificationTypesLazyQueryHookResult = ReturnType<typeof useGetNotificationTypesLazyQuery>;
export type GetNotificationTypesQueryResult = Apollo.QueryResult<gql_GetNotificationTypesQuery, gql_GetNotificationTypesQueryVariables>;
export const GetNotificationTypeDocument = gql`
    query GetNotificationType($id: ID!) {
  notificationType(id: $id) {
    ...NotificationTypeFragment
  }
}
    ${NotificationTypeFragmentFragmentDoc}`;

/**
 * __useGetNotificationTypeQuery__
 *
 * To run a query within a React component, call `useGetNotificationTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNotificationTypeQuery(baseOptions: Apollo.QueryHookOptions<gql_GetNotificationTypeQuery, gql_GetNotificationTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetNotificationTypeQuery, gql_GetNotificationTypeQueryVariables>(GetNotificationTypeDocument, options);
      }
export function useGetNotificationTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetNotificationTypeQuery, gql_GetNotificationTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetNotificationTypeQuery, gql_GetNotificationTypeQueryVariables>(GetNotificationTypeDocument, options);
        }
export type GetNotificationTypeQueryHookResult = ReturnType<typeof useGetNotificationTypeQuery>;
export type GetNotificationTypeLazyQueryHookResult = ReturnType<typeof useGetNotificationTypeLazyQuery>;
export type GetNotificationTypeQueryResult = Apollo.QueryResult<gql_GetNotificationTypeQuery, gql_GetNotificationTypeQueryVariables>;
export const CreateNotificationTypeDocument = gql`
    mutation CreateNotificationType($data: CreateNotificationTypeData!) {
  createNotificationType(data: $data) {
    ...NotificationTypeFragment
  }
}
    ${NotificationTypeFragmentFragmentDoc}`;
export type gql_CreateNotificationTypeMutationFn = Apollo.MutationFunction<gql_CreateNotificationTypeMutation, gql_CreateNotificationTypeMutationVariables>;

/**
 * __useCreateNotificationTypeMutation__
 *
 * To run a mutation, you first call `useCreateNotificationTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationTypeMutation, { data, loading, error }] = useCreateNotificationTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNotificationTypeMutation(baseOptions?: Apollo.MutationHookOptions<gql_CreateNotificationTypeMutation, gql_CreateNotificationTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_CreateNotificationTypeMutation, gql_CreateNotificationTypeMutationVariables>(CreateNotificationTypeDocument, options);
      }
export type CreateNotificationTypeMutationHookResult = ReturnType<typeof useCreateNotificationTypeMutation>;
export type CreateNotificationTypeMutationResult = Apollo.MutationResult<gql_CreateNotificationTypeMutation>;
export type CreateNotificationTypeMutationOptions = Apollo.BaseMutationOptions<gql_CreateNotificationTypeMutation, gql_CreateNotificationTypeMutationVariables>;
export const UpdateNotificationTypeDocument = gql`
    mutation UpdateNotificationType($id: ID!, $data: UpdateNotificationTypeData!) {
  updateNotificationType(id: $id, data: $data) {
    ...NotificationTypeFragment
  }
}
    ${NotificationTypeFragmentFragmentDoc}`;
export type gql_UpdateNotificationTypeMutationFn = Apollo.MutationFunction<gql_UpdateNotificationTypeMutation, gql_UpdateNotificationTypeMutationVariables>;

/**
 * __useUpdateNotificationTypeMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationTypeMutation, { data, loading, error }] = useUpdateNotificationTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateNotificationTypeMutation(baseOptions?: Apollo.MutationHookOptions<gql_UpdateNotificationTypeMutation, gql_UpdateNotificationTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_UpdateNotificationTypeMutation, gql_UpdateNotificationTypeMutationVariables>(UpdateNotificationTypeDocument, options);
      }
export type UpdateNotificationTypeMutationHookResult = ReturnType<typeof useUpdateNotificationTypeMutation>;
export type UpdateNotificationTypeMutationResult = Apollo.MutationResult<gql_UpdateNotificationTypeMutation>;
export type UpdateNotificationTypeMutationOptions = Apollo.BaseMutationOptions<gql_UpdateNotificationTypeMutation, gql_UpdateNotificationTypeMutationVariables>;
export const DeleteNotificationTypeDocument = gql`
    mutation DeleteNotificationType($id: ID!) {
  deleteNotificationType(id: $id)
}
    `;
export type gql_DeleteNotificationTypeMutationFn = Apollo.MutationFunction<gql_DeleteNotificationTypeMutation, gql_DeleteNotificationTypeMutationVariables>;

/**
 * __useDeleteNotificationTypeMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationTypeMutation, { data, loading, error }] = useDeleteNotificationTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationTypeMutation(baseOptions?: Apollo.MutationHookOptions<gql_DeleteNotificationTypeMutation, gql_DeleteNotificationTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_DeleteNotificationTypeMutation, gql_DeleteNotificationTypeMutationVariables>(DeleteNotificationTypeDocument, options);
      }
export type DeleteNotificationTypeMutationHookResult = ReturnType<typeof useDeleteNotificationTypeMutation>;
export type DeleteNotificationTypeMutationResult = Apollo.MutationResult<gql_DeleteNotificationTypeMutation>;
export type DeleteNotificationTypeMutationOptions = Apollo.BaseMutationOptions<gql_DeleteNotificationTypeMutation, gql_DeleteNotificationTypeMutationVariables>;
export const GetNotificationsDocument = gql`
    query GetNotifications($after: String, $before: String, $filters: NotificationFilters, $first: Int, $last: Int, $revert: Boolean, $sortBy: NotificationSortByKeys) {
  total: usersCount
  result: notifications(
    after: $after
    before: $before
    filters: $filters
    first: $first
    last: $last
    revert: $revert
    sortBy: $sortBy
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    nodes {
      ...NotificationFragment
    }
    edges {
      cursor
      node {
        ...NotificationFragment
      }
    }
  }
}
    ${NotificationFragmentFragmentDoc}`;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      revert: // value for 'revert'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<gql_GetNotificationsQuery, gql_GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetNotificationsQuery, gql_GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetNotificationsQuery, gql_GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetNotificationsQuery, gql_GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<gql_GetNotificationsQuery, gql_GetNotificationsQueryVariables>;
export const GetNotificationDocument = gql`
    query GetNotification($id: ID!) {
  notification(id: $id) {
    ...NotificationFragment
  }
}
    ${NotificationFragmentFragmentDoc}`;

/**
 * __useGetNotificationQuery__
 *
 * To run a query within a React component, call `useGetNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNotificationQuery(baseOptions: Apollo.QueryHookOptions<gql_GetNotificationQuery, gql_GetNotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<gql_GetNotificationQuery, gql_GetNotificationQueryVariables>(GetNotificationDocument, options);
      }
export function useGetNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<gql_GetNotificationQuery, gql_GetNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<gql_GetNotificationQuery, gql_GetNotificationQueryVariables>(GetNotificationDocument, options);
        }
export type GetNotificationQueryHookResult = ReturnType<typeof useGetNotificationQuery>;
export type GetNotificationLazyQueryHookResult = ReturnType<typeof useGetNotificationLazyQuery>;
export type GetNotificationQueryResult = Apollo.QueryResult<gql_GetNotificationQuery, gql_GetNotificationQueryVariables>;
export const CreateNotificationDocument = gql`
    mutation CreateNotification($data: CreateNotificationData!) {
  createNotification(data: $data) {
    ...NotificationFragment
  }
}
    ${NotificationFragmentFragmentDoc}`;
export type gql_CreateNotificationMutationFn = Apollo.MutationFunction<gql_CreateNotificationMutation, gql_CreateNotificationMutationVariables>;

/**
 * __useCreateNotificationMutation__
 *
 * To run a mutation, you first call `useCreateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationMutation, { data, loading, error }] = useCreateNotificationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<gql_CreateNotificationMutation, gql_CreateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_CreateNotificationMutation, gql_CreateNotificationMutationVariables>(CreateNotificationDocument, options);
      }
export type CreateNotificationMutationHookResult = ReturnType<typeof useCreateNotificationMutation>;
export type CreateNotificationMutationResult = Apollo.MutationResult<gql_CreateNotificationMutation>;
export type CreateNotificationMutationOptions = Apollo.BaseMutationOptions<gql_CreateNotificationMutation, gql_CreateNotificationMutationVariables>;
export const DeleteNotificationDocument = gql`
    mutation DeleteNotification($id: ID!) {
  deleteNotification(id: $id)
}
    `;
export type gql_DeleteNotificationMutationFn = Apollo.MutationFunction<gql_DeleteNotificationMutation, gql_DeleteNotificationMutationVariables>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationMutation(baseOptions?: Apollo.MutationHookOptions<gql_DeleteNotificationMutation, gql_DeleteNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<gql_DeleteNotificationMutation, gql_DeleteNotificationMutationVariables>(DeleteNotificationDocument, options);
      }
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<gql_DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<gql_DeleteNotificationMutation, gql_DeleteNotificationMutationVariables>;
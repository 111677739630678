/* eslint-disable import/no-anonymous-default-export */
import { VscNotebookTemplate } from 'react-icons/vsc'
import { NotificationTypeCreate } from './NotificationTypeCreate'
import { NotificationTypeEdit } from './NotificationTypeEdit'
import { NotificationTypeList } from './NotificationTypeList'

export default {
  icon: VscNotebookTemplate,
  name: 'NotificationType',
  list: NotificationTypeList,
  create: NotificationTypeCreate,
  edit: NotificationTypeEdit,
}

import React, { FC } from 'react'
import { MUTATION_CREATE_USER } from '../../queries'
import { BaseForm } from '../../components/forms/BaseForm'
import { UserForm } from './UserForm'
import { Create } from '../../components/details/Create'

export const UserCreate: FC = (props) => {
  return (
    <Create mutation={MUTATION_CREATE_USER} {...props}>
      <BaseForm>
        <UserForm {...props} />
      </BaseForm>
    </Create>
  )
}

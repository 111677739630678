import React, { FC } from 'react'
import { MUTATION_CREATE_APP } from '../../queries'
import { BaseForm } from '../../components/forms/BaseForm'
import { AppForm } from './AppForm'
import { Create } from '../../components/details/Create'

const redirectToDetails = (record: any) => `/App/${record.createApp.id}/show`

export const AppCreate: FC = (props) => {
  return (
    <Create mutation={MUTATION_CREATE_APP} redirect={redirectToDetails} {...props}>
      <BaseForm>
        <AppForm {...props} />
      </BaseForm>
    </Create>
  )
}

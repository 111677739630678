import React, { FC } from 'react'
import { Box, Button, Flex, Icon, IconButton } from '@chakra-ui/react'
import { Controller, useFieldArray } from 'react-hook-form'
import { IoIosRemoveCircle } from 'react-icons/io'
import { RiAddCircleFill } from 'react-icons/ri'
import { ca, SelectInput } from 'chakra-admin'
import Editor from '@monaco-editor/react'

export const UserChannelsInput: FC<any> = (props) => {
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control: (props as any).control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'channels', // unique name for your Field Array
  })

  console.log('fields', props)

  return (
    <Box w="100%" py={4}>
      {fields.map((field, index) => (
        <Flex
          key={field.id}
          alignItems="center"
          px={4}
          py={2}
          m={2}
          border="1px solid"
          borderColor="gray.200"
          borderRadius="lg"
        >
          <Box flex="1">
            <SelectInput {...props} source={`channels.${index}.channel`} borderRadius="lg">
              <option value="EMAIL">e-mail</option>
              <option value="SMS">SMS</option>
              <option disabled value="WEB_PUSH">
                Web Push
              </option>
            </SelectInput>

            <Box mt={4} mr={1}>
              <Controller
                name={`channels.${index}.details`}
                control={props.control}
                render={({ field, fieldState }) => {
                  return (
                    <Editor
                      height="250px"
                      width="100%"
                      defaultLanguage="json"
                      // defaultValue={field.value || ''}
                      value={field.value || ''}
                      onChange={field.onChange}
                      options={{
                        minimap: { enabled: false },
                        scrollbar: {
                          handleMouseWheel: false,
                          vertical: 'hidden',
                        },
                      }}
                    />
                  )
                }}
              />
              <ca.Switch {...props} source={`channels.${index}.active`} /> Attivo
            </Box>
          </Box>

          <IconButton
            _hover={{ bg: 'white' }}
            _active={{ bg: 'white' }}
            color="red.500"
            aria-label="Remove"
            size="lg"
            ml={3}
            // alignSelf="flex-end"
            borderRadius="full"
            variant="ghost"
            onClick={() => remove(index)}
            icon={<Icon as={IoIosRemoveCircle} />}
          />
        </Flex>
      ))}
      <Button
        mt={4}
        w="100%"
        size="sm"
        bg="white"
        _hover={{
          bg: 'green.50',
        }}
        borderRadius="lg"
        color="gray.600"
        leftIcon={<Icon as={RiAddCircleFill} color="green.400" fontSize="lg" />}
        justifyContent="flex-start"
        onClick={() =>
          append({
            channel: 'EMAIL',
          })
        }
      >
        Aggiungi Canale
      </Button>
    </Box>
  )
}

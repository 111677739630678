/* eslint-disable import/no-anonymous-default-export */
import { FiUser } from 'react-icons/fi'
import { UserCreate } from './UserCreate'
import { UserEdit } from './UserEdit'
import { UserList } from './UserList'

export default {
  icon: FiUser,
  name: 'User',
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
}

import React, { FC, useCallback, useEffect, useMemo } from 'react'
import {
  Text,
  Heading,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  useToast,
} from '@chakra-ui/react'
import { Section } from '../../components/base/Section'
import { gql_App, useSetEnabledOriginMutation } from '../../graphql'
import { useVersion } from 'chakra-admin'

export const OriginConfiguration: FC<{ record?: gql_App; loading?: boolean }> = ({
  record,
  loading,
}) => {
  const [origin, setOrigin] = React.useState<string | undefined>(record?.enabledOrigin)
  const nextVersion = useVersion()
  const toast = useToast()
  const [setEnabledOrigin, { loading: submitting }] = useSetEnabledOriginMutation({
    onCompleted(data) {
      if (data?.setEnabledOrigin) {
        nextVersion()
        setOrigin(data.setEnabledOrigin)
        toast({
          title: 'Origin abilitata',
          description: `L'origin ${data.setEnabledOrigin} è stata abilitata`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      }
    },
    onError(error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  // const isOriginValidUrl = useMemo(() => {
  //   try {
  //     new URL(origin || '')
  //     return true
  //   } catch (e) {
  //     return false
  //   }
  // }, [origin])

  const isOriginNotSecure = useMemo(() => {
    return !!origin && origin.startsWith('http://')
  }, [origin])

  const isError = useMemo(() => {
    return !origin /* ||  !isOriginValidUrl ||  isOriginNotSecure */
  }, [origin])

  const handleOriginChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setOrigin(event.target.value)
  }, [])

  const handleSubmit = useCallback(
    async (event: any) => {
      event.preventDefault()
      console.log('handleSubmit', origin, record?.id)
      if (!record?.id || isError) {
        return
      }

      await setEnabledOrigin({
        variables: {
          appId: record?.id,
          origin: origin || '',
        },
      })
    },
    [isError, origin, record?.id, setEnabledOrigin]
  )

  useEffect(() => {
    setOrigin(record?.enabledOrigin)
  }, [record?.enabledOrigin])

  return (
    <Section mt={6}>
      <form onSubmit={handleSubmit}>
        <Heading size="md" mb={2}>
          Website origin
        </Heading>
        <Text>HNS restricts the origin of the website where widgets are installed.</Text>

        <Text>
          In order to enable the widgets on your website, you need to set the origin of your website
          in the field below.
        </Text>

        <FormControl mt={4} isInvalid={isError}>
          <FormLabel>Change origin:</FormLabel>
          <Input
            maxW="450px"
            value={origin}
            onChange={handleOriginChange}
            placeholder="https://example.com"
          />
          {isError ? (
            <FormErrorMessage>
              {isOriginNotSecure
                ? 'The origin must be a secure origin (https://)'
                : !origin
                ? 'Please enter an origin'
                : 'The origin must be a valid URL'}
            </FormErrorMessage>
          ) : record?.enabledOrigin ? (
            <FormHelperText>
              <strong>Warning:</strong> changing the origin will disable the widgets on your
              website.
            </FormHelperText>
          ) : null}
        </FormControl>

        <Button
          size="sm"
          mt={4}
          type="submit"
          isDisabled={submitting || isError || !origin}
          isLoading={submitting}
        >
          Save
        </Button>
      </form>
    </Section>
  )
}

type WidgetsConfigurationProps = {
  record?: gql_App
  loading?: boolean
} & Record<string, any>

export const WidgetsConfiguration: FC<WidgetsConfigurationProps> = (props) => {
  return (
    <>
      <OriginConfiguration {...props} />
    </>
  )
}

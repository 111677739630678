import { gql } from '@apollo/client'

export const fragments = {
  Admin: gql`
    fragment AdminFragment on Admin {
      id
      email
      firstName
      lastName
      createdAt
      updatedAt
    }
  `,
  App: gql`
    fragment AppFragment on App {
      id
      name
      active
      hasApiKey
      apiKeyCreatedAt
      hasVapidKeys
      vapidPublicKey
      vapidSignatureMailto
      vapidKeysCreatedAt
      enabledOrigin
      createdAt
      updatedAt
    }
  `,
  AppChannel: gql`
    fragment AppChannelFragment on AppChannel {
      id
      appId
      channel
      value
    }
  `,
  User: gql`
    fragment UserFragment on User {
      id
      appId
      refId
      channels {
        id
        userId
        channel
        details
        active
        createdAt
      }
      app {
        id
        name
      }
      createdAt
      updatedAt
    }
  `,
  NotificationType: gql`
    fragment NotificationTypeFragment on NotificationType {
      id
      appId
      app {
        id
        name
      }
      name
      slug
      description
      notificationCenterTitle
      notificationCenterBody
      notificationCenterIcon
      notificationCenterClickUrl
      notificationCenterIcon
      emailTemplateSubject
      emailTemplateText
      emailTemplateHtmlPath
      tempEmailTemplateHtml
      smsTemplate
      webPushTemplate
      notificationCenterEnabled
      emailEnabled
      smsEnabled
      webPushEnabled
      active
      createdAt
      updatedAt
    }
  `,
  Notification: gql`
    fragment NotificationFragment on Notification {
      id
      appId
      senderId
      typeId
      rawContent
      replacements
      addedToQueueAt
      rawChannels
      notificationType {
        id
        name
        notificationCenterTitle
        emailTemplateSubject
        emailTemplateText
        emailTemplateHtmlPath
        tempEmailTemplateHtml
        smsTemplate
        webPushTemplate
        notificationCenterEnabled
        emailEnabled
        smsEnabled
        webPushEnabled
      }
      scheduledFor
      createdAt
      updatedAt
    }
  `,
}

/** AUTH */
export const QUERY_GET_ADMIN_ME = gql`
  ${fragments.Admin}

  query GetAdminMe {
    adminMe {
      ...AdminFragment
    }
  }
`

export const MUTATION_LOGIN = gql`
  ${fragments.Admin}

  mutation Login($credentials: Credentials!) {
    login(credentials: $credentials) {
      user {
        ...AdminFragment
      }
    }
  }
`

export const MUTATION_LOGOUT = gql`
  mutation Logout {
    logout
  }
`

/** ADMIN */
export const QUERY_GET_ADMINS = gql`
  query GetAdmins(
    $after: String
    $before: String
    $filters: AdminFilters
    $first: Int
    $last: Int
    $revert: Boolean
    $sortBy: AdminSortByKeys
  ) {
    total: adminsCount
    result: admins(
      after: $after
      before: $before
      filters: $filters
      first: $first
      last: $last
      revert: $revert
      sortBy: $sortBy
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      nodes {
        ...AdminFragment
      }
      edges {
        cursor
        node {
          ...AdminFragment
        }
      }
    }
  }

  ${fragments.Admin}
`

export const QUERY_GET_ADMIN = gql`
  ${fragments.Admin}

  query GetAdmin($id: ID!) {
    admin(id: $id) {
      ...AdminFragment
    }
  }
`

export const MUTATION_CREATE_ADMIN = gql`
  ${fragments.Admin}
  mutation CreateAdmin($data: CreateAdminData!) {
    createAdmin(data: $data) {
      ...AdminFragment
    }
  }
`

export const MUTATION_UPDATE_ADMIN = gql`
  ${fragments.Admin}
  mutation UpdateAdmin($id: ID!, $data: UpdateAdminData!) {
    updateAdmin(id: $id, data: $data) {
      ...AdminFragment
    }
  }
`

export const MUTATION_DELETE_ADMIN = gql`
  mutation DeleteAdmin($id: ID!) {
    deleteAdmin(id: $id)
  }
`

/** APP */
export const QUERY_GET_APP = gql`
  ${fragments.App}

  query GetApp($id: ID!) {
    app(id: $id) {
      ...AppFragment
    }
  }
`

export const QUERY_GET_APPS = gql`
  query GetApps(
    $after: String
    $before: String
    $filters: AppFilters
    $first: Int
    $last: Int
    $revert: Boolean
    $sortBy: AppSortByKeys
  ) {
    total: appsCount
    result: apps(
      after: $after
      before: $before
      filters: $filters
      first: $first
      last: $last
      revert: $revert
      sortBy: $sortBy
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      nodes {
        ...AppFragment
      }
      edges {
        cursor
        node {
          ...AppFragment
        }
      }
    }
  }

  ${fragments.App}
`

export const MUTATION_CREATE_APP = gql`
  ${fragments.App}
  mutation CreateApp($data: CreateAppData!) {
    createApp(data: $data) {
      ...AppFragment
    }
  }
`

export const MUTATION_UPDATE_APP = gql`
  ${fragments.App}
  mutation UpdateApp($id: ID!, $data: UpdateAppData!) {
    updateApp(id: $id, data: $data) {
      ...AppFragment
    }
  }
`

export const MUTATION_DELETE_APP = gql`
  mutation DeleteApp($id: ID!) {
    deleteApp(id: $id)
  }
`

export const MUTATION_GENERATE_API_KEY = gql`
  mutation GenerateApiKey($appId: ID!) {
    generateApiKey(appId: $appId) {
      apiKey
    }
  }
`

export const MUTATION_DESTROY_API_KEY = gql`
  mutation DestroyApiKey($appId: ID!) {
    destroyApiKey(appId: $appId)
  }
`

export const MUTATION_GENERATE_VAPID_KEYS = gql`
  mutation GenerateVapidKeys($appId: ID!) {
    generateVapidKeys(appId: $appId) {
      publicKey
    }
  }
`

export const MUTATION_DESTROY_VAPID_KEYS = gql`
  mutation DestroyVapidKeys($appId: ID!) {
    destroyVapidKeys(appId: $appId)
  }
`

export const MUTATION_SET_VAPID_SIGNATURE_MAILTO = gql`
  mutation SetVapidSignatureMailTo($appId: ID!, $mailto: String!) {
    setVapidSignatureMailto(appId: $appId, mailto: $mailto)
  }
`

export const MUTATION_RESET_VAPID_SIGNATURE_MAILTO = gql`
  mutation ResetVapidSignatureMailTo($appId: ID!) {
    resetVapidSignatureMailto(appId: $appId)
  }
`

export const MUTATION_SET_APP_ORIGIN = gql`
  mutation SetEnabledOrigin($appId: ID!, $origin: String!) {
    setEnabledOrigin(appId: $appId, origin: $origin)
  }
`

export const MUTATION_RESET_APP_ORIGIN = gql`
  mutation ResetEnabledOrigin($appId: ID!) {
    resetEnabledOrigin(appId: $appId)
  }
`

export const QUERY_GET_APP_CHANNEL = gql`
  ${fragments.AppChannel}
  query GetAppChannel($appId: ID!, $channel: Channel!) {
    appChannel(appId: $appId, channel: $channel) {
      ...AppChannelFragment
    }
  }
`

export const MUTATION_SET_APP_CHANNEL = gql`
  ${fragments.AppChannel}
  mutation SetAppChannel($appId: ID!, $channel: Channel!, $value: JSON!) {
    setAppChannel(appId: $appId, channel: $channel, value: $value) {
      ...AppChannelFragment
    }
  }
`

/** USER */
export const QUERY_GET_USERS = gql`
  query GetUsers(
    $after: String
    $before: String
    $filters: UserFilters
    $first: Int
    $last: Int
    $revert: Boolean
    $sortBy: UserSortByKeys
  ) {
    total: usersCount
    result: users(
      after: $after
      before: $before
      filters: $filters
      first: $first
      last: $last
      revert: $revert
      sortBy: $sortBy
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      nodes {
        ...UserFragment
      }
      edges {
        cursor
        node {
          ...UserFragment
        }
      }
    }
  }

  ${fragments.User}
`

export const QUERY_GET_USER = gql`
  ${fragments.User}

  query GetUser($id: ID!) {
    user(id: $id) {
      ...UserFragment
    }
  }
`

export const MUTATION_CREATE_USER = gql`
  ${fragments.User}
  mutation CreateUser($data: CreateUserData!) {
    createUser(data: $data) {
      ...UserFragment
    }
  }
`

export const MUTATION_UPDATE_USER = gql`
  ${fragments.User}
  mutation UpdateUser($id: ID!, $data: UpdateUserData!) {
    updateUser(id: $id, data: $data) {
      ...UserFragment
    }
  }
`

export const MUTATION_DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`

/** NOTIFICATION_TYPE */
export const QUERY_GET_NOTIFICATION_TYPES = gql`
  query GetNotificationTypes(
    $after: String
    $before: String
    $filters: NotificationTypeFilters
    $first: Int
    $last: Int
    $revert: Boolean
    $sortBy: NotificationTypeSortByKeys
  ) {
    total: usersCount
    result: notificationTypes(
      after: $after
      before: $before
      filters: $filters
      first: $first
      last: $last
      revert: $revert
      sortBy: $sortBy
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      nodes {
        ...NotificationTypeFragment
      }
      edges {
        cursor
        node {
          ...NotificationTypeFragment
        }
      }
    }
  }

  ${fragments.NotificationType}
`

export const QUERY_GET_NOTIFICATION_TYPE = gql`
  ${fragments.NotificationType}

  query GetNotificationType($id: ID!) {
    notificationType(id: $id) {
      ...NotificationTypeFragment
    }
  }
`

export const MUTATION_CREATE_NOTIFICATION_TYPE = gql`
  ${fragments.NotificationType}
  mutation CreateNotificationType($data: CreateNotificationTypeData!) {
    createNotificationType(data: $data) {
      ...NotificationTypeFragment
    }
  }
`

export const MUTATION_UPDATE_NOTIFICATION_TYPE = gql`
  ${fragments.NotificationType}
  mutation UpdateNotificationType($id: ID!, $data: UpdateNotificationTypeData!) {
    updateNotificationType(id: $id, data: $data) {
      ...NotificationTypeFragment
    }
  }
`

export const MUTATION_DELETE_NOTIFICATION_TYPE = gql`
  mutation DeleteNotificationType($id: ID!) {
    deleteNotificationType(id: $id)
  }
`

/** NOTIFICATION */
export const QUERY_GET_NOTIFICATIONS = gql`
  query GetNotifications(
    $after: String
    $before: String
    $filters: NotificationFilters
    $first: Int
    $last: Int
    $revert: Boolean
    $sortBy: NotificationSortByKeys
  ) {
    total: usersCount
    result: notifications(
      after: $after
      before: $before
      filters: $filters
      first: $first
      last: $last
      revert: $revert
      sortBy: $sortBy
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      nodes {
        ...NotificationFragment
      }
      edges {
        cursor
        node {
          ...NotificationFragment
        }
      }
    }
  }

  ${fragments.Notification}
`

export const QUERY_GET_NOTIFICATION = gql`
  ${fragments.Notification}

  query GetNotification($id: ID!) {
    notification(id: $id) {
      ...NotificationFragment
    }
  }
`

export const MUTATION_CREATE_NOTIFICATION = gql`
  ${fragments.Notification}
  mutation CreateNotification($data: CreateNotificationData!) {
    createNotification(data: $data) {
      ...NotificationFragment
    }
  }
`

// export const MUTATION_UPDATE_NOTIFICATION = gql`
//   ${fragments.Notification}
//   mutation UpdateNotification($id: ID!, $data: UpdateNotificationData!) {
//     updateNotification(id: $id, data: $data) {
//       ...NotificationFragment
//     }
//   }
// `

export const MUTATION_DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($id: ID!) {
    deleteNotification(id: $id)
  }
`

/* eslint-disable import/no-anonymous-default-export */
import { FaAppStore } from 'react-icons/fa'
import { AppCreate } from './AppCreate'
import { AppEdit } from './AppEdit'
import { AppList } from './AppList'
import { AppShow } from './AppShow'

export default {
  icon: FaAppStore,
  name: 'App',
  list: AppList,
  create: AppCreate,
  edit: AppEdit,
  show: AppShow,
}

import { Box, FormControl, FormHelperText, FormLabel, Grid, Input, Stack } from '@chakra-ui/react'
import { AutocompleteInput, useTranslate } from 'chakra-admin'
import React, { FC } from 'react'
import { gql_App } from '../../graphql'
import { QUERY_GET_APPS } from '../../queries'
import { UserChannelsInput } from './UserChannelsInput'

export const UserForm: FC<any> = (props) => {
  const t = useTranslate()
  return (
    <Stack spacing="4" mt={3} mb={20}>
      <Grid
        as={FormControl}
        templateColumns={{ base: 'unset', md: '1fr 2fr' }}
        templateRows={{ base: '1fr 1fr', md: 'unset' }}
        alignItems="baseline"
        columnGap="2"
        isRequired
      >
        <FormLabel htmlFor="refId">{t(`resources.User.fields.appId`)}</FormLabel>
        <AutocompleteInput
          {...props}
          paginationMode="cursor"
          inputStyleProps={{
            variant: 'outline',
            borderRadius: 'lg',
          }}
          labelStyleProps={{
            display: 'none',
          }}
          toggleButtonStyleProps={{
            display: 'none',
          }}
          query={QUERY_GET_APPS}
          source="appId"
          label="resources.User.fields.appId"
          placeholder={t('resources.User.placeholders.appId')}
          disableCreateItem
          showFormControl={false}
          inputValueToFilters={(name: string) => ({ name })}
          dataItemToAutocompleteItem={(data: gql_App) => ({
            ...data,
            label: data.name,
            value: data.id,
          })}
        />
      </Grid>

      <Grid
        as={FormControl}
        templateColumns={{ base: 'unset', md: '1fr 2fr' }}
        templateRows={{ base: '1fr 1fr', md: 'unset' }}
        alignItems="baseline"
        columnGap="2"
        isRequired
      >
        <FormLabel htmlFor="refId">{t(`resources.User.fields.refId`)}</FormLabel>
        <Input
          {...props.register('refId')}
          variant="outline"
          placeholder={t(`resources.User.placeholders.refId`)}
          borderRadius="lg"
        />
      </Grid>

      <UserChannelsInput {...props} />
    </Stack>
  )
}

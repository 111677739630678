import React, { FC, useCallback, useState } from 'react'
import { Box, BoxProps, useBreakpointValue, Icon, Stack, Flex } from '@chakra-ui/react'
import { MotionBox, useGetResourceLabel, useLocalStorage } from 'chakra-admin'
import { SidebarTitle } from './SidebarTitle'
import { ToggleSizeButton } from './ToggleSizeButton'
import { ToggleSizeHoverBorder } from './ToggleSizeHoverBorder'
import { MenuItemLink } from './MenuItemLink'
import { FiUser } from 'react-icons/fi'
import { GiQueenCrown } from 'react-icons/gi'
import { AccountBox } from './AccountBox'
import { useSetCollapsedState } from '../../../../utils/sidebar'
import { FaAppStore } from 'react-icons/fa'
import { VscNotebookTemplate } from 'react-icons/vsc'
import { RiSendPlaneLine } from 'react-icons/ri'

type Props = {} & BoxProps

export const Sidebar: FC<Props> = ({ children, ...rest }) => {
  const getResourceLabel = useGetResourceLabel()
  const [collapsed, setCollapsed] = useLocalStorage<boolean>('ca-default-sidebar-collapsed', false)
  const [isHover, setIsHover] = useState(false)
  const [isExpanderHover, setIsExpanderHover] = useState(false)
  const setCollapsedState = useSetCollapsedState()

  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  })

  const handleToggleSize = useCallback(() => {
    setCollapsed((oldCollapsed) => !oldCollapsed)
    setCollapsedState((oldCollapsed) => !oldCollapsed)
    setIsExpanderHover(false)
  }, [setCollapsed, setCollapsedState])

  const handleMouseEnter = useCallback(() => {
    setIsHover(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setIsHover(false)
  }, [])

  const handleExpanderMouseEnter = useCallback(() => {
    setIsExpanderHover(true)
  }, [])

  const handleExpanderMouseLeave = useCallback(() => {
    setIsExpanderHover(false)
  }, [])

  return (
    <Box
      pos="relative"
      bgColor="white"
      borderRight="1px solid"
      borderRightColor="gray.200"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
    >
      <ToggleSizeHoverBorder isExpanderHover={isExpanderHover} />
      {!isMobile && (
        <ToggleSizeButton
          onHover={handleExpanderMouseEnter}
          onLeave={handleExpanderMouseLeave}
          onClick={handleToggleSize}
          isHover={isHover}
          isCollapsed={collapsed}
        />
      )}
      <MotionBox
        bgColor="white"
        initial={false}
        animate={{ width: isMobile ? 300 : collapsed ? 88 : 300 }}
        pt={{ base: 0, lg: '30px' }}
        pl={5}
        h="100%"
        display="flex"
        flexDir="column"
      >
        {!isMobile && <SidebarTitle onToggleClick={handleToggleSize} isCollapsed={collapsed} />}

        <Flex
          flex="1"
          mt={{ base: 0, lg: 2 }}
          w="100%"
          h="100%"
          flexDir="column"
          overflow="hidden"
          alignItems="center"
        >
          <Box overflowY="auto" w="100%" pr={{ base: 0, lg: 5 }} mb={20}>
            <Stack as="ul" w="100%">
              <MenuItemLink
                icon={<Icon as={RiSendPlaneLine} />}
                to="/Notification"
                label="Notifiche"
                isCollapsed={collapsed}
              />
              <MenuItemLink
                icon={<Icon as={VscNotebookTemplate} />}
                to="/NotificationType"
                label="Tipi di notifica"
                isCollapsed={collapsed}
              />
              <MenuItemLink
                icon={<Icon as={FiUser} />}
                to="/User"
                label="Utenti"
                isCollapsed={collapsed}
              />
              <MenuItemLink
                icon={<Icon as={FaAppStore} />}
                to="/App"
                label="Apps"
                isCollapsed={collapsed}
              />

              <MenuItemLink
                icon={<Icon as={GiQueenCrown} />}
                to="/Admin"
                label="Amministratori"
                isCollapsed={collapsed}
              />
            </Stack>
          </Box>
        </Flex>
        <AccountBox
          isCollapsed={collapsed}
          position="fixed"
          bottom={0}
          minW={{ base: '280px', lg: '260px' }}
          maxW={{ base: '100%', lg: '260px' }}
        />
        {/* <AccountBox
          position="fixed"
          pb={6}
          bottom={0}
          zIndex="1"
          minW="280px"
          maxW={isMobile ? '100%' : '280px'}
        /> */}
      </MotionBox>
    </Box>
  )
}

import {
  DocumentNode,
  gql,
  OperationVariables,
  QueryResult,
  TypedDocumentNode,
} from '@apollo/client'
import {
  ListGetVariablesParams,
  DefaultListStrategy,
  generateFields,
  ListGetVariablesCursorParams,
  PaginationMode,
} from 'chakra-admin'
import { query } from 'gql-query-builder'

export class ListStrategy extends DefaultListStrategy {
  getQuery(
    resource: string,
    operation: string,
    variables?: OperationVariables,
    fields?: string[]
  ): DocumentNode | TypedDocumentNode<any, OperationVariables> {
    const result = query({
      operation,
      variables: {
        pagination: {
          type: 'PaginationInputType',
        },
        filters: { type: `${resource}FilterInput` },
        sort: { type: `${resource}SortInput` },
      },
      fields: ['total', 'offset', 'error', { data: generateFields(fields) }],
    })

    return gql(result.query)
  }

  getList(
    { data }: QueryResult<any, OperationVariables>,
    paginationMode: PaginationMode
  ): Record<string, any>[] {
    console.log('getList', data, paginationMode)
    if (paginationMode === 'offset') {
      console.log(data, Object.keys(data), 'dataKeys')
      return data && Object.keys(data).length > 0 && (data as any)[Object.keys(data)[0]]
        ? (data as any)[Object.keys(data)[0]].data
        : []
    } else if (paginationMode === 'cursor' && data?.result?.edges) {
      return data?.result?.edges.map((edge: any) => edge.node)
    }

    return []
  }

  getTotal(result: QueryResult<any, OperationVariables>) {
    if ((result.data as any)?.total) {
      return (result.data as any).total as number
    } else {
      const dataKeys = Object.keys(result.data)
      if (
        dataKeys.length > 0 &&
        (result.data as any)[dataKeys[0]] &&
        (result.data as any)[dataKeys[0]].total
      ) {
        return (result.data as any)[dataKeys[0]].total as number
      }
    }

    return undefined
  }

  getPageInfo(result: QueryResult<any, OperationVariables>) {
    if (result?.data?.result?.pageInfo) {
      return result.data.result.pageInfo
    }

    return {}
  }

  getVariables(params: ListGetVariablesParams<Record<string, any>>) {
    const {
      sortBy = 'id',
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      resource,
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      paginationMode,
      ...rest
    } = params as ListGetVariablesCursorParams

    return {
      sortBy,
      ...rest,
    }
  }
}
